import { create } from "zustand"

const reservationInfoInitialState = {
  id: null,
  reservationPurpose: "",
  startDate: null,
  endDate: null,
  formName: "",
  defaultInstructor: false,
  typeResa: "",
  tripIds: [],
  freeNotes: "",
}

export const useReservationStore = create((set) => ({
  ...reservationInfoInitialState,
  setReservationInfo: (newData) => set({ ...newData }),
  resetReservation: () => set(reservationInfoInitialState),
}))
