import baseRequest, {
  API_ENDPOINTS,
  AUTH_ENDPOINTS,
  STATUS_ENDPOINTS,
} from "./BaseRequest"
import { toSimpleDate } from "../common/calendarUtils"

export const useRequest = () => {
  const { globalRequest } = baseRequest()

  const onGetApiStatus = async () => {
    return globalRequest.get(STATUS_ENDPOINTS.GET_STATUS_URI).json()
  }

  /**
   * Request pour se connecter
   * @param username
   * @param password
   * @returns {Promise<unknown>}
   */
  const onLoginSubmit = async (username, password) => {
    return globalRequest
      .post(AUTH_ENDPOINTS.LOGIN_URI, {
        searchParams: {
          user_name: username,
          password: password,
        },
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .json()
  }

  const onLogoutSubmit = async (user_id) => {
    return globalRequest
      .post(AUTH_ENDPOINTS.LOGOUT_URI, {
        searchParams: {
          id: user_id,
        },
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .json()
  }

  /**
   * Request pour obtenir le contenu d'une date
   * @param date
   * @param nb
   */
  const onHandleDate = async (date, nb) => {
    let simpleDate = toSimpleDate(date)
    return globalRequest
      .get(API_ENDPOINTS.GET_DAY_URI, {
        searchParams: {
          date: simpleDate,
          nb: nb || 0,
        },
      })
      .json()
  }

  /**
   * Request pour mettre à jour une journée ou un trip (Fermeture, etc...)
   * @param id
   * @param value
   * @param property
   * @param type
   * @returns {Promise<unknown>}
   */
  const onUpdateProperties = async (id, value, property, type) => {
    const uri =
      type === "day"
        ? API_ENDPOINTS.SET_DAY_PROPERTY_URI
        : API_ENDPOINTS.SET_TRIP_PROPERTY_URI

    return globalRequest
      .post(uri + property, {
        searchParams: {
          id: id,
          [property]: value,
        },
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .json()
  }

  /**
   * Request pour trier les réservations
   * @param trip_id
   * @param tab_resa
   * @returns {Promise<*>}
   */
  const onSortResa = async (trip_id, tab_resa) => {
    return globalRequest.post(API_ENDPOINTS.SORT_RESA_URI, {
      searchParams: {
        trip_id: trip_id,
        tab_id_resa: tab_resa,
      },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
  }

  /**
   * Request pour supprimer une réservation complète
   * @param id
   * @param start
   * @param end
   * @returns {Promise<unknown>}
   */
  const onHandleDeleteResa = async (id, start, end) => {
    return globalRequest
      .delete(API_ENDPOINTS.DEL_RESA_URI, {
        searchParams: {
          id: id,
          start: start,
          end: end,
        },
      })
      .json()
  }

  /**
   * Request pour supprimer une réservation partielle
   * @param utc_date
   * @param tripId
   * @param resaId
   * @returns {Promise<unknown>}
   */
  const onHandleDeletePartialResa = async (utc_date, tripId, resaId) => {
    return globalRequest
      .delete(API_ENDPOINTS.DEL_PARTIAL_RESA_URI, {
        searchParams: {
          date: utc_date,
          trip: tripId,
          id: resaId,
        },
      })
      .json()
  }

  /**
   * Request pour créer / mettre à jour une réservation
   * @param request
   * @returns {Promise<unknown>}
   */
  const onHandleSubmit = async (request) => {
    const {
      requestURI,
      id,
      startDate,
      endDate,
      isOption,
      typeResa,
      tripIds,
      freeNotes,
      lastName,
      firstName,
      phoneNumber,
      diveLevel,
      reservationPurpose,
      formName,
      groupId,
      diverId,
      clubName,
      clubContact,
      clubDiversCount,
      clubEncCount,
      clubEncSupNeeded,
      clubAutoEnc,
      defaultInstructor,
    } = request

    if (!requestURI) return

    const requestData = {
      id,
      startResa: startDate,
      endResa: endDate,
      isOption,
      typeResa,
      tripIds,
      freeNotes,
    }

    switch (typeResa) {
      case "DIVER":
        requestData.diver = {
          diverId,
          name: lastName,
          firstName,
          phoneNumber,
          lvl: diveLevel,
        }
        requestData.status = reservationPurpose
        requestData.formName = formName
        requestData.defaultInstructor = defaultInstructor
        break
      case "GROUP":
        requestData.group = {
          groupId,
          name: clubName,
          phoneNumber: clubContact,
          clubDiversCount,
          clubEncCount,
          clubEncSupNeeded,
          clubAutoEnc,
        }
        requestData.status = typeResa
        break
      default:
        break
    }
    return globalRequest
      .put(requestURI, {
        body: JSON.stringify(requestData),
        headers: {
          "Content-Type": "application/json",
        },
      })
      .json()
  }

  /**
   * Request pour obtenir les pourcentages d'une période
   * @param start
   * @param end
   * @returns {Promise<unknown>}
   */
  const onGetBasicCalendar = async (start, end) => {
    return globalRequest
      .get(API_ENDPOINTS.GET_BASIC_CAL, {
        searchParams: {
          startDate: start,
          endDate: end,
        },
      })
      .json()
  }

  /**
   * Request pour obtenir le détail d'une réservation
   * @param id
   * @param start
   * @param end
   * @returns {Promise<unknown>}
   */
  const onGetTripsOfResa = async (id, start, end) => {
    return globalRequest
      .get(API_ENDPOINTS.GET_TRIPS_OF_RESA, {
        searchParams: {
          id: id,
          start: start,
          end: end,
        },
      })
      .json()
  }

  /**
   * Request pour supprimer un trip
   * @param tripId
   * @returns {Promise<*>}
   */
  const onDeleteTrip = async (tripId) => {
    return globalRequest
      .delete(API_ENDPOINTS.DEL_TRIP_URI, {
        searchParams: {
          trip_id: tripId,
        },
      })
      .json()
  }

  /**
   * Request pour ajouter un trip
   * @returns {Promise<*>}
   * @param trip
   */
  const onAddTrip = async (trip) => {
    return globalRequest
      .put(API_ENDPOINTS.ADD_TRIP_URI, {
        body: JSON.stringify(trip),
        headers: {
          "Content-Type": "application/json",
        },
      })
      .json()
  }

  return {
    onGetApiStatus,
    onLoginSubmit,
    onLogoutSubmit,
    onHandleDate,
    onHandleSubmit,
    onHandleDeleteResa,
    onHandleDeletePartialResa,
    onUpdateProperties,
    onGetBasicCalendar,
    onGetTripsOfResa,
    onSortResa,
    onDeleteTrip,
    onAddTrip,
  }
}
