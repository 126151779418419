import { Btn } from "../../Buttons/Btn"
import { CheckIcon } from "@heroicons/react/24/outline"

export default function AdminDashboard({ handleCancel }) {
  return (
    <div className="flex h-full flex-col">
      <div className={"mt-8 flex min-h-0 flex-1 flex-col space-y-7"}></div>

      <Btn
        onClick={handleCancel}
        label={"Fermer"}
        afterIcon={CheckIcon}
        style={"primary-btn"}
      />
    </div>
  )
}
