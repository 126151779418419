import React, { memo } from "react"
import { ExclamationTriangleIcon, QuestionMarkCircleIcon } from "@heroicons/react/24/outline"
import clsx from "clsx"

const GroupHeader = memo(function({ group, isOption, freeNotes, itemProperties }) {
  const getLabelEncCount = (count) => {
    if (count === 0) {
      return "Pas d'encadrant supplémentaire"
    }

    const label =
      count === 1 ? "Encadrant supplémentaire" : "Encadrants supplémentaires"

    return `${count} ${label}`
  }

  const IconWithCondition = ({ condition, Icon, className, title }) =>
    condition && <Icon className={className} title={title} />

  return (
    <div className="flex w-full pr-1">
      <div className={clsx("w-full justify-start text-left")}>
        <div className="flex flex-wrap">
          {group?.name}
          <IconWithCondition
            condition={isOption}
            Icon={QuestionMarkCircleIcon}
            className="ml-1 mt-0.5 h-5 w-5"
          />
          <IconWithCondition
            condition={freeNotes}
            Icon={ExclamationTriangleIcon}
            className="ml-1 mt-0.5 h-5 w-5 text-red-800 dark:text-red-500"
          />
        </div>
      </div>
      <div className={clsx("w-full justify-end text-right")}>
        {group?.clubDiversCount !== undefined && (
          <div className={itemProperties?.textColor}>
            {`${group?.clubDiversCount} plongeurs`}
          </div>
        )}
        {group?.clubAutoEnc && <div>{"Auto-encadrés"}</div>}
        {group?.clubEncSupNeeded && (
          <div className={""}>
            {getLabelEncCount(group?.clubEncCount)}
          </div>
        )}
      </div>
    </div>
  )


})
export default GroupHeader