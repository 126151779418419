import { XMarkIcon } from "@heroicons/react/24/outline"
import { useModalStore } from "../../common/stores/modal.store"
import clsx from "clsx"
import React, { memo } from "react"
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react"
import { CANCEL_BTN_LABEL } from "../../common/constants"
import dompurify from "dompurify"
import { Btn } from "../Buttons/Btn"
import { NoSymbolIcon } from "@heroicons/react/24/outline/index"

const Modal = memo(function Modal({ handleSubmit }) {
  const { form: FormComponent, ...modal } = useModalStore()

  const handleReset = (e) => {
    modal.toggleModal()
    setTimeout(() => {
      modal.reset()
    }, 500)
  }

  const handleSubmiting = (request) => {
    handleSubmit(request)
    handleReset()
  }

  const handleConfirm = (e) => {
    e.preventDefault()
    modal.confirmCallback()
    handleReset()
  }

  return (
    <Dialog open={modal.show} onClose={handleReset} className="relative z-10">
      <DialogBackdrop
        onClick={handleReset}
        transition
        className={clsx(
          "fixed inset-0 bg-black/20 backdrop-blur-sm transition-opacity",
          "data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200",
          "data-[enter]:ease-out data-[leave]:ease-in",
        )}
      />
      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className={clsx(
              "w-full",
              "relative transform overflow-hidden rounded-lg bg-white px-4 pb-4",
              "pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4",
              "data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200",
              "data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg",
              "sm:p-4 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95",
            )}
          >
            <div
              className={clsx(
                !modal.xCross && "hidden",
                "absolute right-0 top-0 pr-4 pt-4 sm:block",
              )}
            >
              <button
                type="button"
                onClick={handleReset}
                className={clsx(
                  "rounded-md bg-white text-gray-400 hover:text-gray-500",
                )}
              >
                <span className="sr-only">Close</span>
                <XMarkIcon aria-hidden="true" className="h-6 w-6" />
              </button>
            </div>

            <div className="sm:flex sm:items-start">
              {!modal.noIcon && (
                <div
                  className={clsx(
                    "mx-auto flex h-12 w-12 flex-shrink-0 items-center",
                    "justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10",
                    modal.level.bgColor,
                  )}
                >
                  <modal.level.icon
                    className={clsx("h-6 w-6", modal.level.color)}
                    aria-hidden="true"
                  />
                </div>
              )}

              <div
                className={clsx(
                  !modal.noIcon && "mt-3 sm:ml-4 sm:mt-0",
                  "w-full pt-1 text-center sm:mt-0 sm:text-left",
                )}
              >
                <DialogTitle
                  as="h3"
                  className="text-base font-semibold leading-6 text-gray-900"
                >
                  {modal.title}
                </DialogTitle>
                <div className="mt-2 w-full">
                  {FormComponent && (
                    <FormComponent
                      handleSubmit={handleSubmiting}
                      handleCancel={handleReset}
                      formData={modal.formData}
                      props={modal.formProps}
                    />
                  )}

                  {modal.message && (
                    <p
                      className="font-normal leading-6 text-gray-500"
                      dangerouslySetInnerHTML={{
                        __html: dompurify
                          .sanitize(modal.message)
                          .replace(/\n/g, "<br>"),
                      }}
                    ></p>
                  )}
                </div>
              </div>
            </div>

            {!modal.isForm && (
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                {modal.labelSubmit && (
                  <Btn
                    onClick={handleConfirm}
                    style={"danger-btn sm:ml-3 sm:w-auto"}
                    label={modal.labelSubmit}
                    afterIcon={XMarkIcon}
                  />
                )}
                {!modal.noCancel && (
                  <Btn
                    onClick={handleReset}
                    style={"secondary-btn mt-3 sm:mt-0 sm:w-auto"}
                    label={CANCEL_BTN_LABEL}
                    afterIcon={NoSymbolIcon}
                    border={true}
                  />
                )}
              </div>
            )}
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  )
})

export default Modal
