import { useEffect, useState } from "react"
import {
  FORM_CLUB_AUTO_ENC,
  FORM_CLUB_DIVER_COUNT,
  FORM_CLUB_ENC_COMP_NECESSARY,
  FORM_CLUB_ENC_COUNT,
  FORM_CLUB_NAME,
  FORM_FREE_NOTES_RESA,
  FORM_PHONE_RESA,
  RESA,
} from "../../common/constants"
import Picker from "../Picker/Picker"
import { getStartOfDay } from "../../common/calendarUtils"
import SelectTrip from "./FormComponents/SelectTrip"
import { useReservationStore } from "../../common/stores/reservation.store"
import { slideStore } from "../../common/stores/slide.store"
import { clubStore } from "../../common/stores/club.store"
import FormComponent from "./FormComponents/FormComponent"
import ActionBtnContainer from "./FormComponents/ActionBtnContainer"
import LabelRequiredInfo from "./FormComponents/LabelRequiredInfo"
import Checkbox from "../Checkbox/Checkbox"
import { Transition } from "@headlessui/react"

export default function FormGroup({ handleSubmit, handleCancel }) {
  const [error, setError] = useState(new Map())
  const { requestURI, task } = slideStore()
  const fieldsToUppercase = []
  const fieldsNumber = ["clubContact", "clubDiversCount", "clubEncCount"]

  const [club, setClub] = useState(clubStore.getState())
  const [reservation, setReservation] = useState(useReservationStore.getState())

  useEffect(() => {
    return () => {
      club.resetClub()
      reservation.resetReservation()
    }
  }, [])

  /**
   * Event saisie date reservation
   * @param dateValue
   */
  const handleDateChange = (dateValue) => {
    let start = getStartOfDay(dateValue.startDate)
    let end = getStartOfDay(dateValue.endDate)
    setReservation((prevState) => ({
      ...prevState,
      startDate: start,
      endDate: end,
    }))
  }

  const handleGroup = (event) => {
    const { name, value } = event.target
    if (fieldsNumber.includes(name)) {
      const numbersOnly = value.replace(/[^0-9]/g, "")
      setClub((prevState) => ({
        ...prevState,
        [name]: numbersOnly,
      }))
    } else {
      const updatedValue = fieldsToUppercase.includes(name)
        ? value.toUpperCase()
        : value
      setClub((prevState) => ({
        ...prevState,
        [name]: updatedValue,
      }))
    }
  }

  const handleReservation = (event) => {
    const { name, value } = event.target
    if (fieldsNumber.includes(name)) {
      const numbersOnly = value.replace(/[^0-9]/g, "")
      setReservation((prevState) => ({
        ...prevState,
        [name]: numbersOnly,
      }))
    } else {
      setReservation((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
  }

  /**
   * Event soumission du formulaire
   * @param event
   */
  const handleFormSubmit = (event) => {
    event.preventDefault()
    validateAndSubmit()
  }

  /**
   * Event soumission formulaire en option
   * @param event
   */
  const handleOptionFormSubmit = (event) => {
    event.preventDefault()
    validateAndSubmit(true)
  }

  const validateForm = () => {
    const formErrors = {
      clubName: !club.clubName,
      clubContact: !club.clubContact,
      clubDiversCount: club.clubDiversCount < 1,
      clubEncCount: club.clubEncSupNeeded && club.clubEncCount < 1,
      startDate: !reservation.startDate,
      endDate: !reservation.endDate,
      tripIds: reservation.startDate && reservation.tripIds.length === 0,
    }
    setError(formErrors)
    return Object.values(formErrors).every((error) => !error)
  }

  const validateAndSubmit = (isOption = false) => {
    console.log(club)
    if (validateForm()) {
      handleSubmit({
        ...club,
        ...reservation,
        isOption,
        requestURI,
        typeResa: "GROUP",
      })
    }
  }

  return (
    <form
      onSubmit={handleFormSubmit}
      className="flex h-full w-full flex-col"
      noValidate
    >
      <div className="mt-8 flex min-h-0 flex-1 flex-col space-y-7">
        <div className="sm:col-span-6">
          <Picker
            onDateChange={handleDateChange}
            error={error["startDate"] || error["endDate"]}
            startDate={reservation[RESA.START.field]}
            endDate={reservation[RESA.END.field]}
          />
        </div>
        <div className="sm:col-span-6">
          <SelectTrip
            error={error["tripIds"]}
            setReservation={setReservation}
            reservation={reservation}
            task={task}
          />
        </div>
        <div className="sm:col-span-6">
          <FormComponent
            id={"clubName"}
            name={"clubName"}
            label={FORM_CLUB_NAME}
            type={"text"}
            value={club.clubName}
            onChange={handleGroup}
            onDelete={() =>
              handleGroup({
                target: { value: "", name: "clubName" },
              })
            }
            error={error["clubName"]}
            required={true}
          />
        </div>
        <div className="sm:col-span-6">
          <FormComponent
            id={"phone-clubContact"}
            name={"clubContact"}
            label={FORM_PHONE_RESA}
            type={"tel"}
            value={club.clubContact}
            onChange={handleGroup}
            onDelete={() =>
              handleGroup({
                target: { value: "", name: "clubContact" },
              })
            }
            error={error["clubContact"]}
            required={true}
            maxLength={15}
          />
        </div>
        <div className="flex items-center">
          <div className="block w-full leading-6">{FORM_CLUB_DIVER_COUNT}</div>
          <div className="relative w-3/6 lg:w-2/6">
            <FormComponent
              id={"clubDiversCount"}
              name={"clubDiversCount"}
              label={"Plongeur(s)"}
              value={club.clubDiversCount}
              onChange={handleGroup}
              onDelete={() =>
                handleGroup({
                  target: {
                    value: "",
                    name: "clubDiversCount",
                  },
                })
              }
              maxLength={2}
              error={error["clubDiversCount"]}
              required={true}
            />
          </div>
        </div>
        <div className="sm:col-span-6">
          <div className="relative flex justify-between">
            <div className="h-6">
              <Checkbox
                id={"clubAutoEnc"}
                name={"clubAutoEnc"}
                defaultChecked={club.clubAutoEnc}
                labelColor={"standard-text-color"}
                colorChecked={"standard-text-color"}
                handleChange={() =>
                  handleGroup({
                    target: {
                      value: !club.clubAutoEnc,
                      name: "clubAutoEnc",
                    },
                  })
                }
              />
            </div>
            <label htmlFor="clubAutoEnc" className="block leading-6">
              {FORM_CLUB_AUTO_ENC}
            </label>
          </div>
        </div>
        <div className="sm:col-span-6">
          <div className="relative flex justify-between">
            <div className="h-6">
              <Checkbox
                id={"clubEncSupNeeded"}
                name={"clubEncSupNeeded"}
                defaultChecked={club.clubEncSupNeeded}
                labelColor={"standard-text-color"}
                colorChecked={"standard-text-color"}
                handleChange={() => {
                  handleGroup({
                    target: {
                      value: !club.clubEncSupNeeded,
                      name: "clubEncSupNeeded",
                    },
                  })

                  // Reset clubEncCount if clubEncSupNeeded is unchecked
                  if ((club.clubEncSupNeeded) && (club.clubEncCount !== "")) {
                    setTimeout(() => {
                      setClub((prevState) => ({
                        ...prevState,
                        clubEncCount: ""
                      }))
                    }, 300)
                  }}
                }
              />
            </div>
            <label htmlFor="clubEncSupNeeded" className="block leading-6">
              {FORM_CLUB_ENC_COMP_NECESSARY}
            </label>
          </div>
        </div>

        <Transition
          show={club.clubEncSupNeeded}
          as="div" unmount={false}
          appear={true}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="flex items-center">
            <div className="block w-full leading-6">{FORM_CLUB_ENC_COUNT}</div>
            <div className="w-3/6 lg:w-2/6">
              <FormComponent
                id={"clubEncCount"}
                name={"clubEncCount"}
                label={"Encadrant(s)"}
                value={club.clubEncCount}
                onChange={handleGroup}
                onDelete={() =>
                  handleGroup({
                    target: {
                      value: "",
                      name: "clubEncCount",
                    },
                  })
                }
                maxLength={2}
                error={error["clubEncCount"]}
                required={true}
              />
            </div>
          </div>
        </Transition>

        <div className="sm:col-span-6">
          <FormComponent
            id={"freeNotes"}
            name={"freeNotes"}
            label={FORM_FREE_NOTES_RESA}
            type={"text"}
            value={reservation.freeNotes}
            onChange={handleReservation}
            textarea={true}
            crossDelete={false}
          />
        </div>

        <LabelRequiredInfo />
      </div>

      <ActionBtnContainer
        handleCancel={handleCancel}
        handleOptionFormSubmit={handleOptionFormSubmit}
        handleFormSubmit={handleFormSubmit}
      />
    </form>
  )
}
