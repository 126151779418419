import clsx from "clsx"
import React from "react"
import { EditText } from "react-edit-text"
import { InformationCircleIcon } from "@heroicons/react/24/outline"

export default function BackdropClose({ show, cause, type, id, handleChange }) {
  const idCause = type + "cause_" + id

  return (
    show && (
      <div
        className={clsx(
          "absolute z-3 h-full w-full bg-gray-50/70",
          "dark:bg-[#191e24]/60",
        )}
      >
        <div
          className={clsx(
            "relative flex h-full w-full content-center justify-center p-3",
          )}
        >
          <div
            className={clsx(
              "absolute top-1/2 z-4 rounded-lg border border-gray-100 bg-white px-4 py-2.5",
              "dark:border-gray-800 dark:bg-[#191e24]",
              "shadow-sm dark:shadow-lg",
            )}
          >
            <div className="relative flex pl-1 gap-x-4">
              {/*<label className="flex">*/}
              {/*    <InformationCircleIcon className={"h-6 w-6"} />*/}
              {/*</label>*/}

              <div className="relative">
                <>
                  <EditText
                    id={idCause}
                    inputClassName="border-0 focus:ring-0 truncate dark:text-gray-400 placeholder:text-white dark:placeholder-gray-600"
                    name="cause"
                    placeholder="Motif (optionnel)"
                    defaultValue={cause}
                    onSave={(valueObj) =>
                      handleChange(
                        idCause,
                        valueObj.value,
                        valueObj.previousValue,
                      )
                    }
                    style={{
                      backgroundColor: "transparent",
                      color: cause ? "#4b5563" : "#999",
                    }}
                    inline
                  />
                </>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  )
}
