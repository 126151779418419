import clsx from "clsx"

export default function TripInfo({ children }) {
  return (
    <div className={clsx("w-full px-4 pb-3")}>
      <div
        className={clsx(
          "std-bg flex flex-wrap justify-between px-3 py-2",
          "rounded-lg border border-gray-200 shadow-sm dark:shadow-lg",
          "dark:bg-[#191e24]",
        )}
      >
        {children}
      </div>
    </div>
  )
}
