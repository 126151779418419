import React, { memo, useMemo } from "react"
import clsx from "clsx"
import { useDaysStore } from "../../../common/stores/days.store"
import { useShallow } from "zustand/react/shallow"

const Date = memo(function Date({ date, readOnly, isToday, isCurrentMonth }) {
  const { selectedDate } = useDaysStore(
    useShallow((s) => ({
      selectedDate: s.selectedDate,
    })),
  )

  const isSelected = useMemo(
    () => date.isSame(selectedDate, "day") && isCurrentMonth,
    [date, selectedDate, isCurrentMonth],
  )

  const containerClasses = useMemo(
    () =>
      clsx(
        "font-medium lg:text-sm",
        "absolute flex h-8 w-8 items-center justify-center rounded-full",
        "bg-white/60",
        "dark:bg-gray-800 dark:bg-opacity-70",
        isSelected && !readOnly
          ? "font-extrabold text-black ring-1 ring-black"
          : "ring-gray-900/5",
        isToday && "font-extrabold text-main",
      ),
    [isSelected, readOnly, isToday],
  )

  return (
    <time className={containerClasses} dateTime={date.toISOString()}>
      {date.date()}
    </time>
  )
})

export default Date
