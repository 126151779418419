import clsx from "clsx"
import { Separator } from "./HeaderComponents/Separator"
import { AvatarHeader } from "./HeaderComponents/AvatarHeader"
import { handleDate } from "../../common/stores/days.store"
import dayjs from "dayjs"
import { Btn } from "../Buttons/Btn"
import calendarIcon from "@heroicons/react/24/outline/esm/CalendarIcon"
import { useCallback } from "react"

export default function Header() {
  const now = dayjs()
  const now_day = now.format("DD") || ""

  const handleDateClick = useCallback(() => {
    handleDate(now, true)
  }, [])

  return (
    <header
      className={clsx(
        "h-16 lg:h-20",
        "sticky top-0 z-6 flex items-center border-b px-6",
        "std-bg",
      )}
    >
      <div className="flex flex-1 self-stretch lg:gap-x-6">
        <div className={"flex flex-1"}></div>

        <div className="flex items-center gap-x-4 lg:gap-x-6">
          <Btn
            free_style={"relative"}
            pictoH={"h-10"}
            pictoW={"w-10"}
            title={"Aujourd'hui"}
            stroke={"stroke-1"}
            beforeIcon={calendarIcon}
            onClick={handleDateClick}
          >
            <span
              className={
                "absolute left-5 top-5 ml-[2.5px] mt-[3px] lg:mt-[3.5px]"
              }
            >
              {now_day}
            </span>
          </Btn>

          {/*<ThemeChoice />*/}

          <Separator />

          <AvatarHeader />
        </div>
      </div>
    </header>
  )
}
