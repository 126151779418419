import { MOTIF_ITEM } from "../../../../common/constants"
import clsx from "clsx"
import TripInfo from "./TripInfo"

export default function TripStatus({ tripStatus }) {
  return (
    <TripInfo>
      {tripStatus &&
        Object.entries(tripStatus).map(
          ([diversStatus, value], index) =>
            diversStatus !== "GROUP" && (
              <div
                key={index}
                className={clsx(
                  MOTIF_ITEM[diversStatus].textColor,
                  "block px-1",
                )}
                title={MOTIF_ITEM[diversStatus].longLibelle}
              >
                {MOTIF_ITEM[diversStatus].shortLibelle + value}
              </div>
            ),
        )}
    </TripInfo>
  )
}
