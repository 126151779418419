import React, { useEffect, useRef, useState } from "react"
import clsx from "clsx"
import FormComponent from "./FormComponents/FormComponent"
import dayjs from "dayjs"
import ActionBtnContainer from "./FormComponents/ActionBtnContainer"

export default function FormAddTrip({ formData, handleCancel, handleSubmit }) {
  const [tripStartAt, setTripStartAt] = useState("00:00")
  const [tripSite, setTripSite] = useState("")
  const [tripDiversMax, setTripDiversMax] = useState(20)
  const [error, setError] = useState(new Map())
  const formDataRef = useRef(null)
  const { date, dayId } = formData

  useEffect(() => {
    formDataRef.current = {
      tripStartAt: tripStartAt,
      tripSite: tripSite,
      tripDiversMax: tripDiversMax,
    }
  }, [tripStartAt, tripSite, tripDiversMax])

  const handleStartAtChange = (event) => {
    setTripStartAt(event.target.value)
  }

  const handleNbDiversChange = (event) => {
    const inputNbDivers = event.target.value
    const numbersOnly = inputNbDivers.replace(/[^0-9]/g, "")
    setTripDiversMax(numbersOnly)
  }

  const handleSiteChange = (event) => {
    setTripSite(event.target.value)
  }

  const handleFormSubmit = (event) => {
    event.preventDefault()
    validateAndSubmit()
  }

  const validateForm = () => {
    const formErrors = {
      tripStartAt: !tripStartAt || tripStartAt === "00:00",
    }
    setError(formErrors)
    return Object.values(formErrors).every((error) => !error)
  }

  const validateAndSubmit = () => {
    if (validateForm()) {
      handleSubmit({
        ...formDataRef.current,
        dayId: dayId,
        tripStartAt: dayjs(
          `${dayjs(date).format("YYYY-MM-DDT")} ${tripStartAt}`,
          { utc: true },
        ),
      })
    }
  }

  return (
    <form onSubmit={handleFormSubmit} className={clsx("flex h-full")}>
      <div className="mt-8 flex min-h-0 flex-1 flex-col space-y-7">
        <div className="flex space-x-4 sm:col-span-6">
          <div className="w-1/2">
            <FormComponent
              id={"tripStartAt"}
              label={"Heure de départ"}
              type={"time"}
              value={tripStartAt}
              onChange={handleStartAtChange}
              onDelete={() => handleStartAtChange({ target: { value: "" } })}
              error={error["tripStartAt"]}
              required={true}
              crossDelete={false}
            />
          </div>
          <div className="w-1/2">
            <FormComponent
              id={"tripDiversMax"}
              label={"Plongeurs Max"}
              type={"text"}
              value={tripDiversMax}
              onChange={handleNbDiversChange}
              onDelete={() => handleNbDiversChange({ target: { value: "" } })}
              maxLength={2}
            />
          </div>
        </div>

        <div className="sm:col-span-6">
          <FormComponent
            id={"tripSite"}
            label={"Site"}
            type={"text"}
            value={tripSite}
            onChange={handleSiteChange}
            onDelete={() => handleSiteChange({ target: { value: "" } })}
          />
        </div>

        <ActionBtnContainer
          handleFormSubmit={handleFormSubmit}
          handleCancel={handleCancel}
        />
      </div>
    </form>
  )
}
