import Sidebar from "../components/Sidebar/Sidebar"
import Header from "../components/Header/Header"
import Slide from "../components/Slide/Slide"
import Modal from "../components/Modal/Modal"
import { showToast } from "../common/toastUtils"
import { Logger } from "../common/logger"
import { toast } from "react-toastify"
import { ERROR_ITEM } from "../common/constants"
import { useRequest } from "../services/UseRequest"
import Content from "../components/Content/Content"
import { useDaysStore } from "../common/stores/days.store"
import { useShallow } from "zustand/react/shallow"
import { memo } from "react"
import { refresh } from "../common/utils"

/**
 * Composant principal de l'application
 * @returns {JSX.Element|null}
 * @constructor
 */
const Main = memo(function Main() {
  const { updateDays, setRefresh } = useDaysStore(
    useShallow((state) => ({
      updateDays: state.updateDays,
      setRefresh: state.setRefresh,
    })),
  )

  const { onHandleSubmit, onUpdateProperties, onDeleteTrip, onAddTrip } =
    useRequest()

  /**
   * Méthode pour mettre à jour une journée (Fermeture, etc...)
   * @param idComponent
   * @param value
   * @param type
   * @returns {Promise<void>}
   */
  const updateProperties = (idComponent, value, type) => {
    let property = idComponent.split("_")[0]
    let id = idComponent.split("_")[1]
    onUpdateProperties(id, value, property, type)
      .then((response) => {
        updateDays(response)
      })
      .catch((error) => {
        Logger.error(error)
      })
      .finally(() => {
        refresh()
      })
  }

  /**
   * Methode pour supprimer une sortie
   * @param tripId
   * @returns {Promise<string>}
   */
  const deleteTrip = (tripId) => {
    onDeleteTrip(tripId)
      .then((response) => {
        updateDays(response)
      })
      .catch((error) => {
        Logger.error(error)
      })
      .finally(() => {
        refresh()
      })
  }

  /**
   * Methode pour ajouter une sortie
   * @param trip
   * @returns {Promise<string>}
   */
  const addTrip = (trip) => {
    onAddTrip(trip)
      .then((response) => {
        updateDays(response)
      })
      .catch((error) => {
        Logger.error(error)
      })
      .finally(() => {
        setRefresh(true)
      })
  }

  /**
   * Methode pour créer / mettre à jour une réservation
   * @param request
   * @returns {Promise<void>}
   **/
  const handleSubmit = async (request) => {
    if (request.requestURI) {
      await toast.promise(onHandleSubmit(request), {
        success: {
          render({ data }) {
            updateDays(data?.days)
            refresh()
            return "Enregistrement réussi !"
          },
        },
        error: ERROR_ITEM,
      })
    } else {
      showToast(true, "URI not formatted")
    }
  }

  return (
    <div className={"flex h-screen"}>
      {/*Sidebar*/}
      <Sidebar />

      <div className="h-full w-full overflow-hidden">
        {/*Header*/}
        <Header />

        <Content updateProperties={updateProperties} deleteTrip={deleteTrip} />
      </div>

      {/*Slide*/}
      <Slide handleSubmit={handleSubmit} />

      {/*Modal*/}
      <Modal handleSubmit={addTrip} />
    </div>
  )
})

export default Main
