import BackdropClose from "../../utilities/BackdropClose"
import DayHeader from "./DayComponents/DayHeader"
import { DAY } from "../../../common/constants"
import { useDaysStore } from "../../../common/stores/days.store"
import dayjs from "dayjs"
import { memo } from "react"
import SkeletonDay from "../../Loading/SkeletonDay"
import DayContent from "./DayComponents/DayContent"

const Day = memo(function Month({ date, updateProperties, deleteTrip }) {
  const day = useDaysStore((state) =>
    state.days.find((day) => dayjs(day.date).isSame(date, "day")),
  )

  const { isDayClosed, isDayTrip } = day || {}
  const cause = day?.closedCause || ""

  const handleChange = (id, newValue, previousValue) => {
    if (newValue !== previousValue) {
      updateProperties(id, newValue, DAY)
    }
  }

  return (
    <div className={"flex h-full p-0 lg:p-1 lg:pb-0"}>
      <div
        className={
          "day-bg relative flex-1 overflow-hidden lg:rounded-xl lg:rounded-b-none lg:shadow-box"
        }
      >
        <BackdropClose
          show={isDayClosed || false}
          cause={cause}
          type={DAY}
          id={day?.dayId}
          handleChange={handleChange}
        />

        <DayHeader date={date} isDayClosed={isDayClosed} dayId={day?.dayId} />

        {day ? (
          <DayContent
            day={day}
            updateProperties={updateProperties}
            deleteTrip={deleteTrip}
          />
        ) : (
          <SkeletonDay tripNumber={2} />
        )}
      </div>
    </div>
  )
})

export default Day
