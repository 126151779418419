import { UserGroupIcon, UserIcon } from "@heroicons/react/24/solid"
import FormDiver from "../components/Form/FormDiver"
import FormGroup from "../components/Form/FormGroup"
import { API_ENDPOINTS } from "../services/BaseRequest"
import informationCircleIcon from "@heroicons/react/24/outline/esm/InformationCircleIcon"
import exclamationCircleIcon from "@heroicons/react/24/outline/esm/ExclamationCircleIcon"
import exclamationTriangleIcon from "@heroicons/react/24/outline/esm/ExclamationTriangleIcon"
import questionMarkCircleIcon from "@heroicons/react/24/outline/esm/QuestionMarkCircleIcon"
import checkIcon from "@heroicons/react/24/outline/esm/CheckIcon"

export const monthsToShow = 3

export const DAY = "day"
export const TRIP = "trip"
export const LABEL_DEL_RESA = "Supprimer réservation"
export const LABEL_DEL_PARTIAL_RESA = "Supprimer créneau"
export const LABEL_DAY_TRIP = "Sortie à la journée"
export const LABEL_DAY_CLOSE = "Fermeture journée"
export const LABEL_DAY_OPEN = "Ouverture journée"

export const LABEL_TRIP_ADD = "Ajouter une sortie"

export const LABEL_TRIP_CLOSE = "Fermeture sortie"
export const LABEL_TRIP_OPEN = "Ouverture sortie"

export const LABEL_DELETE_TRIP = "Supprimer sortie"
export const LABEL_CONNEXION = "Se connecter"

export const CONFIRM_PATTERN = "Êtes-vous sûr de vouloir "

export const MESSAGE_DAY_TRIP_YES =
  CONFIRM_PATTERN +
  "faire une sortie à la journée ? \n Toutes les autres sorties seront supprimées."
export const MESSAGE_DAY_TRIP_NO =
  CONFIRM_PATTERN + "retirer cette sortie à la journée ?"

export const MESSAGE_DAY_CLOSE =
  CONFIRM_PATTERN +
  "fermer cette journée ? \n Toutes les réservations du jour seront supprimées."
export const MESSAGE_TRIP_DELETE =
  CONFIRM_PATTERN +
  "supprimer cette sortie ? \n Toutes les réservations de la sortie seront supprimées."
export const MESSAGE_TRIP_CLOSE =
  CONFIRM_PATTERN +
  "fermer ce créneau ? \n Toutes les réservations du créneau seront supprimées."

export const MESSAGE_DAY_OPEN = CONFIRM_PATTERN + "ouvrir cette journée ?"
export const MESSAGE_TRIP_OPEN = CONFIRM_PATTERN + "ouvrir ce créneau ?"

export const MESSAGE_DEL_RESA =
  CONFIRM_PATTERN +
  "supprimer cette réservation ? \n Cette action est irréversible."
export const MESSAGE_DEL_PARTIAL_RESA =
  CONFIRM_PATTERN + "supprimer ce créneau ? \n Cette action est irréversible."

export const TITLE_UPDATE_RESA = "Modifier réservation"
export const TITLE_DUPLICATE_RESA = "Dupliquer réservation"
export const TITLE_CREATE_RESA_DIVER = "Nouvelle réservation plongeur"
export const TITLE_CREATE_RESA_GROUP = "Nouvelle réservation groupe"

export const FORM_DATE_RESA = "Dates de réservation"
export const FORM_PHONE_RESA = "Téléphone"
export const FORM_FREE_NOTES_RESA = "Commentaires"

export const NEXT_MONTH_CALENDAR = "Mois suivant"
export const PREVIOUS_MONTH_CALENDAR = "Mois précédent"
export const DAY_SHORT_LABEL = ["Lun", "Mar", "Mer", "Jeu", "Ven", "Sam", "Dim"]

export const FORM_CLUB_NAME = "Club / responsable"
export const FORM_CLUB_DIVER_COUNT = "Nombre de plongeurs : "
export const FORM_CLUB_ENC_COUNT = "Nombre d'encadrants : "
export const FORM_CLUB_ENC_COMP_NECESSARY =
  "Encadrement complémentaire nécessaire"
export const FORM_CLUB_AUTO_ENC = "Auto-Encadrés"

export const CANCEL_BTN_LABEL = "Annuler"
export const DELETE_RESA_BTN_LABEL = "Supprimer la réservation"
export const DELETE_PARTIAL_RESA_BTN_LABEL = "Supprimer le créneau"
export const DELETE_TRIP_BTN_LABEL = "Supprimer la sortie"
export const CLOSE_TRIP_BTN_LABEL = "Fermer la sortie"
export const OPEN_TRIP_BTN_LABEL = "Ouvrir la sortie"

export const SUBMIT_BTN_LABEL = "Valider"
export const OPTION_BTN_LABEL = "Option"
export const CLOSE_DAY_BTN_LABEL = "Fermer la journée"
export const OPEN_DAY_BTN_LABEL = "Ouvrir la journée"

export const DAY_TRIP_LABEL = "Journée"
export const MORNING_TRIP_LABEL = "Matin"
export const AFTERNOON_TRIP_LABEL = "Après-midi"
export const TWILIGHT_TRIP_LABEL = "Crépusculaire"

export const DECONNEXION = "Se déconnecter"
export const ABOUT_ITEM = "A propos"

export const ERROR_CONNEXION_ITEM = "Erreur connexion"
export const ERROR_ITEM = "Une erreur est survenue"

export const TOGGLE_ITEM_CLOSED = "Fermé / OFF"
export const TOGGLE_ITEM_DAY_TRIP = "Sortie journée"

export const ENCADRANT = "ENCADRANT"
export const FORMATION = "FORMATION"
export const BAPTEME = "BAPTEME"
export const AENCADRER = "AENCADRER"
export const AUTONOMIE = "AUTONOMIE"

export const SEPARATOR = " - "

export const SELECT_ITEM = "Sélectionnez les créneaux"
export const SELECTED_ITEM = "Créneaux Sélectionnés"
export const ERROR_ITEM_INPUT = "Veuillez saisir"
export const ERROR_ITEM_SELECT = "Veuillez sélectionner"
export const LABEL_REQUIRED_INFO = "Champs requis"
export const LABEL_REQUIRED = "*"

/*DIVER ITEM*/
export const DIVER = {
  FIRST_NAME: {
    label: "Prénom",
    field: "firstName",
  },
  LAST_NAME: {
    label: "Nom",
    field: "lastName",
  },
  PHONE: {
    label: "Téléphone",
    field: "phoneNumber",
  },
  LEVEL: {
    label: "Niveau",
    field: "diveLevel",
  },
}

/*RESERVATION ITEM*/
export const RESA = {
  START: {
    label: "Début",
    field: "startDate",
  },
  END: {
    label: "Fin",
    field: "endDate",
  },
  PURPOSE: {
    label: "Motif de la réservation",
    field: "reservationPurpose",
  },
  FREE_NOTES: {
    label: "Commentaires",
    field: "freeNotes",
  },
  FORM_NAME: {
    label: "Libellé de la formation",
    field: "formName",
  },
  DEFAULT_INSTRUCTOR: {
    label: "Encadrant par défaut",
    field: "defaultInstructor",
  },
}

export const NAV_ITEM = {
  diver: {
    label: "Nouveau plongeur",
    icon: UserIcon,
    id: "newDiver",
    form: FormDiver,
    title: TITLE_CREATE_RESA_DIVER,
    URI: API_ENDPOINTS.ADD_RESA_URI,
  },
  group: {
    label: "Nouveau groupe",
    icon: UserGroupIcon,
    id: "newGroup",
    form: FormGroup,
    title: TITLE_CREATE_RESA_GROUP,
    URI: API_ENDPOINTS.ADD_RESA_URI,
  },
}

export const MOTIF_ITEM = {
  BAPTEME: {
    className: "bapteme",
    textColor: "text-purple-700 dark:text-purple-600",
    bgColor: "bg-purple-100 dark:bg-[#301c45]",
    borderColor: "border-purple-300/50 dark:border-purple-700/50",
    pastille: "bg-purple-300/50 dark:bg-purple-700/50",
    statusItem: BAPTEME,
    shortLibelle: "Bapt: ",
    longLibelle: "Baptême",
  },
  AUTONOMIE: {
    className: "autonomie",
    textColor: "text-blue-700 dark:text-blue-600",
    bgColor: "bg-blue-100 dark:bg-[#22283e]",
    borderColor: "border-blue-300/50 dark:border-blue-700/50",
    pastille: "bg-blue-300/50 dark:bg-blue-700/50",
    statusItem: AUTONOMIE,
    shortLibelle: "Explo: ",
    longLibelle: "Autonomie",
  },
  FORMATION: {
    className: "formation",
    textColor: "text-yellow-800 dark:text-yellow-600",
    bgColor: "bg-yellow-100 dark:bg-[#33261f]",
    borderColor: "border-yellow-300/50 dark:border-yellow-700/50",
    pastille: "bg-yellow-300/50 dark:bg-yellow-700/50",
    statusItem: FORMATION,
    shortLibelle: "Form: ",
    longLibelle: "Formation",
  },
  AENCADRER: {
    className: "aencadrer",
    textColor: "text-red-700 dark:text-red-600",
    bgColor: "bg-red-100 dark:bg-[#341e21]",
    borderColor: "border-red-300/50 dark:border-red-700/50",
    pastille: "bg-red-300/50 dark:bg-red-700/50",
    statusItem: AENCADRER,
    shortLibelle: "AE: ",
    longLibelle: "A Encadrer",
  },
  ENCADRANT: {
    className: "encadrant",
    textColor: "text-green-700 dark:text-green-600",
    bgColor: "bg-green-100 dark:bg-[#1b2c25]",
    borderColor: "border-green-400/50 dark:border-green-700/50",
    pastille: "bg-green-400/50 dark:bg-green-700/50",
    statusItem: ENCADRANT,
    shortLibelle: "Enc: ",
    longLibelle: "Encadrant",
  },
  OPTION: {
    className: "option",
    textColor: "text-gray-600 dark:text-gray-600",
    bgColor: "bg-gray-100 dark:bg-gray-[#21242a]",
    borderColor: "border-gray-300/50 dark:border-gray-700/50",
    pastille: "bg-gray-300/50 dark:bg-gray-700/50",
  },
  GROUP: {
    className: "group",
    textColor: "text-orange-600 dark:text-orange-600",
    bgColor: "bg-orange-100 dark:bg-[#33221f]",
    borderColor: "border-orange-300/50 dark:border-orange-700/50",
    pastille: "bg-orange-300/50 dark:bg-orange-700/50",
  },
  DEFAULT: {
    className: "default",
    textColor: "text-gray-600 dark:text-gray-600",
    bgColor: "bg-gray-100 dark:bg-gray-[#21242a]",
    borderColor: "border-gray-300/50 dark:border-gray-700/50",
    pastille: "bg-gray-300/50 dark:bg-gray-700/50",
  }
}

export const LEVEL = {
  OK: {
    label: "Ok",
    icon: checkIcon,
    color: "text-green-600",
    bgColor: "bg-green-100",
  },
  INFO: {
    label: "Information",
    icon: informationCircleIcon,
    color: "text-blue-600",
    bgColor: "bg-blue-100",
  },
  ERROR: {
    label: "Erreur",
    icon: exclamationCircleIcon,
    color: "text-red-600",
    bgColor: "bg-red-100",
  },
  WARN: {
    label: "Attention",
    icon: exclamationTriangleIcon,
    color: "text-red-600",
    bgColor: "bg-red-100",
  },
  HELP: {
    label: "Aide",
    icon: questionMarkCircleIcon,
    color: "text-blue-600",
    bgColor: "bg-blue-100",
  },
}

export const TASK = {
  FULL_NEW: 0,
  TRIP_NEW: 1,
  UPDATE: 2,
  DUPLICATE: 3,
}
