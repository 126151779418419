import React, { memo, useCallback, useEffect, useMemo, useState } from "react"
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid"
import dayjs from "dayjs"
import Month from "./CalendarComponents/Month"
import {
  NEXT_MONTH_CALENDAR,
  PREVIOUS_MONTH_CALENDAR,
} from "../../common/constants"
import { generateCalendar } from "../../common/calendarUtils"
import { useDaysStore } from "../../common/stores/days.store"
import { useShallow } from "zustand/react/shallow"

const Calendar = memo(function Calendar({
  readOnly = false,
  monthsToShow = 1,
  classDefault,
}) {
  const { selectedDate } = useDaysStore(
    useShallow((state) => ({
      selectedDate: state.selectedDate,
    })),
  )

  const [calState, setCalState] = useState({
    currentYear: dayjs(selectedDate).year(),
    currentMonthIdx: dayjs(selectedDate).month(),
  })

  useEffect(() => {
    setCalState({
      currentYear: dayjs(selectedDate).year(),
      currentMonthIdx: dayjs(selectedDate).month(),
    })
  }, [selectedDate])

  const handleMonthChange = useCallback((increment) => {
    setCalState((prevState) => ({
      currentYear:
        prevState.currentYear +
        Math.floor((prevState.currentMonthIdx + increment) / 12),
      currentMonthIdx: (prevState.currentMonthIdx + increment + 12) % 12,
    }))
  }, [])

  const months = useMemo(() => {
    return Array.from({ length: monthsToShow }, (_, i) => {
      const monthIdx = (calState.currentMonthIdx + i) % 12
      const yearOffset = Math.floor((calState.currentMonthIdx + i) / 12)
      return generateCalendar(calState.currentYear + yearOffset, monthIdx, true)
    })
  }, [calState.currentYear, calState.currentMonthIdx, monthsToShow])

  return (
    <div className="relative px-1 pt-2">
      <button
        type="button"
        className="absolute left-0 top-0 flex items-center justify-center p-2"
        onClick={() => handleMonthChange(-1)}
      >
        <span className="sr-only">{PREVIOUS_MONTH_CALENDAR}</span>
        <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
      </button>
      <button
        type="button"
        className="absolute right-0 top-0 flex items-center justify-center p-2"
        onClick={() => handleMonthChange(1)}
      >
        <span className="sr-only">{NEXT_MONTH_CALENDAR}</span>
        <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
      </button>
      <div className="space-y-8">
        {months.map((month, i) => (
          <Month
            key={i}
            month={month}
            readOnly={readOnly}
            classDefault={classDefault}
            timerNumber={i + 1}
          />
        ))}
      </div>
    </div>
  )
})

export default Calendar
