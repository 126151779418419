import Trip from "../../Trip/Trip"
import SvgClose from "../../../utilities/SvgClose"
import { Logger } from "../../../../common/logger"
import { memo } from "react"

const DayBody = memo(function DayBody({ day, updateProperties, deleteTrip }) {
  const { dayId, trips, isDayClosed, isDayTrip } = day

  Logger.debug("DayBody.jsx")

  const deletingTrip = (tripId) => {
    deleteTrip(tripId, dayId)
  }

  return (
    <>
      {isDayClosed ? (
        <SvgClose height={"p-5 h-fulled"} />
      ) : (
        trips?.map((trip) => (
          <Trip
            key={trip.tripId}
            isDayTrip={isDayTrip}
            trip={trip}
            updateProperties={updateProperties}
            deleteTrip={deletingTrip}
          />
        ))
      )}
    </>
  )
})

export default DayBody
