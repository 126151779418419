import { create } from "zustand"

const clubInfoInitialState = {
  groupId: null,
  clubName: "",
  clubContact: "",
  clubDiversCount: "",
  clubEncCount: "",
  clubEncSupNeeded: false,
  clubAutoEnc: false,
}

export const clubStore = create((set) => ({
  ...clubInfoInitialState,
  setClubInfo: (newData) => set({ ...newData }),
  resetClub: () => set(clubInfoInitialState),
}))
