import { ERROR_ITEM_INPUT } from "../../../common/constants"
import clsx from "clsx"

export default function ErrorMessage({
  hasError,
  label,
  errorItem = ERROR_ITEM_INPUT,
}) {
  return (
    hasError && (
      <p
        className={clsx(
          "absolute ml-1 mt-0.5 animate-shake text-xs italic text-red-600",
          "dark:text-red-400",
        )}
      >
        {`${errorItem} ${label.toLowerCase()}.`}
      </p>
    )
  )
}
