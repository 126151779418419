import { PhoneIcon } from "@heroicons/react/24/outline"
import { formatPhoneNumber } from "../../../../common/utils"
import { toSimpleDate } from "../../../../common/calendarUtils"
import ResaContainerBtn from "./ResaContainerBtn"
import dompurify from "dompurify"
import clsx from "clsx"
import { memo } from "react"

const ResaDetailPanel = memo(function ResaDetailPanel({
  resa,
  dayId,
  tripId,
  itemProperties,
  phone,
}) {
  const { startResa, endResa } = resa

  return (
    <>
      <div className={"grid w-full grid-cols-1 gap-y-2 pt-2"}>
        {/*Numéro de téléphone*/}
        <div className={"flex items-center gap-x-1"}>
          <PhoneIcon className={"h-4 w-4"} />
          {phone !== undefined && (
            <a href={"tel:" + phone}>{formatPhoneNumber(phone)}</a>
          )}
        </div>

        <div className={"flex flex-wrap justify-between"}>
          {/*Date de reservation*/}
          <div>
            <div className={"flex flex-wrap gap-x-1"}>
              <span>Du</span>
              <span className={"font-medium"}>{toSimpleDate(startResa)}</span>
            </div>
            <div className={"flex flex-wrap gap-x-1"}>
              <span>Au</span>
              <span className={"font-medium"}>{toSimpleDate(endResa)}</span>
            </div>
          </div>

          {/*Btn de gestion resa*/}
          <ResaContainerBtn
            resa={resa}
            dayId={dayId}
            tripId={tripId}
            itemProperties={itemProperties}
          />
        </div>
      </div>

      {resa?.freeNotes && (
        <div
          className={clsx(
            "mt-2 max-h-96 w-full overflow-y-auto rounded-lg border py-2 px-3",
            "bg-white/80 text-gray-500",
            "dark:border-gray-800 dark:bg-white/5 dark:text-gray-400",
            itemProperties?.borderColor,
          )}
          dangerouslySetInnerHTML={{
            __html: dompurify.sanitize(resa?.freeNotes).replace(/\n/g, "<br>"),
          }}
        />
      )}
    </>
  )
})

export default ResaDetailPanel
