import { NAV_ITEM, TASK } from "../../../common/constants"
import BtnAddDiver from "../../Buttons/BtnAddDiver"
import React from "react"
import clsx from "clsx"

export default function SidebarMenu({ toggleSidebar }) {
  return (
    <div className={clsx("pl-1")}>
      {Object.entries(NAV_ITEM).map(([item, value], index) => {
        return (
          <BtnAddDiver
            key={value.id}
            item={value}
            disable={false}
            asSingle={false}
            disabled={false}
            task={TASK.FULL_NEW}
            toggleSidebar={toggleSidebar}
          />
        )
      })}
    </div>
  )
}
