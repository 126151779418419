import React, { memo, useEffect, useState } from "react"
import { toCapitelize } from "../../../common/utils"
import Loader from "../../Loading/Loader"
import { useRequest } from "../../../services/UseRequest"
import clsx from "clsx"
import Day_name from "./Day_name"
import Week from "./Week"
import { Logger } from "../../../common/logger"
import { formatDate } from "../../../common/calendarUtils"
import { useDaysStore } from "../../../common/stores/days.store"
import { useShallow } from "zustand/react/shallow"

const Month = memo(function Month({
  month,
  timerNumber,
  readOnly = false,
  classDefault,
}) {
  const { refresh, setRefresh } = useDaysStore(
    useShallow((state) => ({
      refresh: state.refresh,
      setRefresh: state.setRefresh,
    })),
  )

  const { onGetBasicCalendar } = useRequest()
  const { weeks, name } = month
  const start = formatDate(month.firstDate)
  const end = formatDate(month.lastDate)
  const [dataMonth, setDataMonth] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setDataMonth(() => {
      return []
    })
    setIsLoading(true)
  }, [name])

  useEffect(() => {
    if (start && end) {
      let isMounted = true
      const timer = setTimeout(() => {
        start &&
          end &&
          onGetBasicCalendar(start, end)
            .then((response) => {
              if (isMounted) {
                setDataMonth(response)
              }
            })
            .catch((error) => {
              Logger.error(error)
              if (isMounted) {
                setDataMonth([])
              }
            })
            .finally(() => {
              setIsLoading(false)
              setRefresh(false)
            })
      }, timerNumber * 100)

      return () => {
        isMounted = false
        clearTimeout(timer)
      }
    }
  }, [start, end, refresh])

  const weeksArray = Array.from(weeks)
  const classDefaultMonth = () => {
    return clsx(
      "gap-px",
      "isolate grid grid-cols-7 overflow-hidden rounded-lg shadow ring-1",
      readOnly && "bg-white ring-white dark:bg-gray-800 dark:ring-gray-800",
      !readOnly && dataMonth.length > 0
        ? "bg-white ring-white dark:bg-gray-800 dark:ring-gray-800"
        : "bg-gray-200 ring-gray-200 dark:bg-gray-700 dark:ring-gray-700",
    )
  }

  return (
    <div className="text-center pb-2">
      <div className="pb-2.5 font-medium">{toCapitelize(name)}</div>

      <Day_name />

      <div className={clsx("relative")}>
        {
          <div className={classDefaultMonth()} >
            {weeksArray.map(([num_week, week]) => {
              return (
                <Week
                  week={week}
                  key={num_week}
                  readOnly={readOnly}
                  classDefault={classDefault}
                  dataMonth={dataMonth}
                />
              )
            })}
            {/* Loader au-dessus du contenu principal */}
            {isLoading && dataMonth.length === 0 && (
              <div className="absolute inset-0 z-20">
                <Loader height={"h-5"} width={"w-5"} />
              </div>
            )}
          </div>
        }
      </div>
    </div>
  )
})
export default Month
