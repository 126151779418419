import clsx from "clsx"
import { APP_LOGO } from "../../../services/BaseRequest"

export default function SidebarHeader({ position, border, logo, classes }) {
  return (
    <div
      className={clsx(
        "title-bar",
        "h-16 lg:h-20",
        "flex w-full items-center",
        "std-bg",
        position && position,
        border && "border-b",
        classes && classes,
      )}
    >
      {logo && (
        <img
          className={"relative w-20"}
          src={APP_LOGO}
          alt={"DivePlanner Logo"}
        />
      )}

      <div className={clsx("truncate pb-0.5 font-medium")}>
        Dive
        <span className={"span-title"}>O</span>
        Planner
      </div>
    </div>
  )
}
