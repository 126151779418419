import { create } from "zustand"

const diverInfoInitialState = {
  diverId: null,
  firstName: "",
  lastName: "",
  phoneNumber: "",
  diveLevel: "",
}

export const diverStore = create((set) => ({
  ...diverInfoInitialState,
  setDiverInfo: (newData) => set({ ...newData }),
  resetDiver: () => set(diverInfoInitialState),
}))
