import { MOTIF_ITEM } from "../../../common/constants"
import Pastille from "./ReservationComponents/Pastille"
import ResaDetailPanel from "./ReservationComponents/ResaDetailPanel"
import React, { memo } from "react"
import { Accordion } from "@szhsin/react-accordion"
import AccordionItem from "../../Accordion/AccordionItem"
import clsx from "clsx"
import DiverHeader from "./ReservationComponents/DiverHeader"
import GroupHeader from "./ReservationComponents/GroupHeader"

const Reservation = memo(function Reservation({ resa, dayId, tripId }) {
  const { status, isOption, typeResa, formName, freeNotes, defaultInstructor } =
    resa

  const itemProperties = isOption ? MOTIF_ITEM["OPTION"] : MOTIF_ITEM[status]
  const isGroup = typeResa === "GROUP"
  const phone = isGroup ? resa.group?.phoneNumber : resa.diver?.phoneNumber

  return (
    <div
      className={clsx(
        "relative flex min-h-16 w-full rounded-lg border pl-9 shadow-sm dark:shadow",
        itemProperties?.borderColor,
        itemProperties?.bgColor,
      )}
    >
      <Pastille itemProperties={itemProperties} />
      <div className={"w-full p-2"}>
        <Accordion transition transitionTimeout={250}>
          <AccordionItem
            itemProperties={itemProperties}
            header={
              isGroup ? (
                <GroupHeader
                  freeNotes={freeNotes}
                  group={resa.group}
                  isOption={isOption}
                  itemProperties={itemProperties}
                />
              ) : (
                <DiverHeader
                  status={status}
                  formName={formName}
                  diver={resa.diver}
                  isOption={isOption}
                  freeNotes={freeNotes}
                  itemProperties={itemProperties}
                  defaultInstructor={defaultInstructor}
                />
              )
            }
          >
            <ResaDetailPanel
              resa={resa}
              dayId={dayId}
              tripId={tripId}
              itemProperties={itemProperties}
              phone={phone}
            />
          </AccordionItem>
        </Accordion>
      </div>
    </div>
  )
})

export default Reservation
