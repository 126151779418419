import React, { memo, useEffect, useState } from "react"

import { getPeriodsLabel } from "../../../../common/utils"
import { TrashIcon } from "@heroicons/react/24/outline"

import { EditText } from "react-edit-text"
import clsx from "clsx"
import { Btn } from "../../../Buttons/Btn"
import {
  DELETE_TRIP_BTN_LABEL,
  LABEL_DELETE_TRIP,
  LEVEL,
  MESSAGE_TRIP_DELETE,
} from "../../../../common/constants"
import { useModalStore } from "../../../../common/stores/modal.store"
import { useShallow } from "zustand/react/shallow"

const TripHeader = memo(function TripHeader({
  tripId,
  tripStartAt,
  isDayTrip,
  tripDiversCount,
  tripDiversMax,
  handleChange,
  isTripClosed,
  deleteTrip,
  setExiting,
}) {
  const [count, setCount] = useState(tripDiversMax.toString())
  const id = "maxDiverTrip_" + tripId

  useEffect(() => {
    setCount(tripDiversMax.toString())
  }, [tripDiversMax])

  const { setModalData, toggleModal } = useModalStore(
    useShallow((state) => ({
      setModalData: state.setModalData,
      toggleModal: state.toggleModal,
    })),
  )

  const handleDeleteTrip = () => {
    setModalData({
      title: LABEL_DELETE_TRIP,
      message: MESSAGE_TRIP_DELETE,
      labelSubmit: DELETE_TRIP_BTN_LABEL,
      level: LEVEL.WARN,
      confirmCallback: () => {
        deleteTrip(tripId)
        setExiting(true)
      },
    })
    toggleModal()
  }

  const onChange = (e, setFn) => {
    const val = e.target.value
    if (/^\d*$/.test(val) && val.length <= 2 && val !== "0") {
      setFn(val)
    }
  }

  const onSave = ({ name, value, previousValue }) => {
    const parseVal = /^\d*$/.test(value) && value !== "" ? value : "1"
    handleChange(id, parseVal, previousValue)
    if (parseVal === previousValue) setCount(parseVal)
  }

  return (
    <div
      className={clsx(
        "white-text-color w-full bg-secondary p-2 dark:bg-gray-800",
        !isTripClosed && "sticky top-0 z-20",
      )}
    >
      <div className={"relative flex flex-wrap"}>
        <div className={"relative mx-auto flex pr-5 font-medium"}>
          <label className={"flex pr-1"}>
            {getPeriodsLabel(tripStartAt, isDayTrip) +
              " : " +
              tripDiversCount +
              " /"}
          </label>
          <div className={"relative"}>
            <EditText
              id={id}
              inputClassName="border-0 focus:ring-0 absolute truncate"
              name="maxDiverTrip"
              type="text"
              value={count}
              onChange={(e) => onChange(e, setCount)}
              onSave={onSave}
              style={{
                width: "25px",
                backgroundColor: "transparent",
                padding: "0",
                position: "absolute",
                color: "white",
              }}
              inline
            />
          </div>
        </div>

        <Btn
          free_style={"absolute right-0 -m-2"}
          onClick={handleDeleteTrip}
          title={"Supprimer la sortie"}
        >
          <TrashIcon className={"h-5 w-5"} aria-hidden="true" />
        </Btn>
      </div>
    </div>
  )
})

export default TripHeader
