import { useState } from "react"
import FormComponent from "./FormComponents/FormComponent"
import { Btn } from "../Buttons/Btn"
import { LABEL_CONNEXION } from "../../common/constants"

export default function FormLogin({ handleLogin }) {
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(new Map())

  const handleSubmitting = (event, user, passwd) => {
    event.preventDefault()
    setIsLoading(true)
    setError((prevState) => ({
      ...prevState,
      username: !username,
      password: !password,
    }))

    handleLogin(user, passwd)
      .catch((error) => {
        console.error(error)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <form className="space-y-6">
      <div className="sm:col-span-6">
        <FormComponent
          id={"username"}
          value={username}
          onChange={(event) => {
            setUsername(event.target.value)
            setError((prevState) => ({
              ...prevState,
              username: !event.target.value,
            }))
          }}
          label={"Nom d'utilisateur"}
          // crossDelete={false}
          error={error.username}
          required={true}
          onDelete={() => setUsername("")}
        />
      </div>

      <div className="sm:col-span-6">
        <FormComponent
          id={"password"}
          value={password}
          onChange={(event) => {
            setPassword(event.target.value)
            setError((prevState) => ({
              ...prevState,
              password: !event.target.value,
            }))
          }}
          label={"Mot de passe"}
          type={"password"}
          crossDelete={false}
          eye={true}
          error={error.password}
          required={true}
          onDelete={() => setPassword("")}
        />
      </div>
      <div>
        <Btn
          isLoading={isLoading}
          onClick={(event) => handleSubmitting(event, username, password)}
          label={LABEL_CONNEXION}
          style={"primary-btn"}
          type={"submit"}
        />
      </div>
    </form>
  )
}
