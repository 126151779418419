import React, { Fragment, memo } from "react"
import SidebarHeader from "./SidebarComponents/SidebarHeader"
import SidebarBtnToggle from "./SidebarComponents/SidebarBtnToggle"
import clsx from "clsx"
import Backdrop from "../utilities/Backdrop"
import { monthsToShow } from "../../common/constants"
import { useSessionStorage } from "../../common/hooks/UseSessionStorage"
import { sidebarStore } from "../../common/stores/sidebar.store"
import { useShallow } from "zustand/react/shallow"
import SidebarBody from "./SidebarComponents/SidebarBody"
import SidebarMenu from "./SidebarComponents/SidebarMenu"
import Calendar from "../Calendar/Calendar"
import { Transition } from "@headlessui/react"
import SidebarFooter from "./SidebarComponents/SidebarFooter"

const Sidebar = memo(function Sidebar() {
  const { isSidebarVisible, toggleSidebar, toggleMobile } = sidebarStore(
    useShallow((state) => ({
      isSidebarVisible: state.isSidebarVisible,
      toggleSidebar: state.toggleSidebar,
      toggleMobile: state.toggleMobile,
    })),
  )

  const [session] = useSessionStorage("session")

  const getMonthToShow = () => {
    return session?.user?.userPref?.monthsToDisplay || monthsToShow
  }

  return (
    <>
      <Backdrop onClick={toggleSidebar} show={isSidebarVisible} />

      <aside
        className={clsx(
          "std-bg z-8 flex h-full flex-shrink-0 flex-col",
          "fixed lg:relative lg:border-r",
          "transform duration-300 ease-in-out",
          "w-11/12 max-w-md",
          isSidebarVisible
            ? "lg:w-96"
            : "-translate-x-full lg:w-20 lg:-translate-x-0",
        )}
      >
        <SidebarBtnToggle />

        <SidebarHeader position={"fixed"} border={true} logo={true} />

        <SidebarBody>
          <>
            <SidebarMenu toggleSidebar={toggleMobile} />
            <Transition
              show={isSidebarVisible}
              as="div" unmount={false}
              appear={true}
              enter="lg:ease-out lg:duration-500"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Calendar monthsToShow={getMonthToShow()} />
            </Transition>
          </>
        </SidebarBody>

        {/*<SidebarFooter />*/}
      </aside>
    </>
  )
})

export default Sidebar
