import React from "react"
import clsx from "clsx"
import "swiper/css"

export default function DragHandle({ attributes, listeners }) {
  return (
    <button
      className={clsx(
        "absolute flex h-full w-9 cursor-grab touch-none items-center justify-center rounded-l-lg p-2 pl-3",
        "fill-gray-400",
        "hover:bg-gray-100/50 hover:fill-gray-500",
        "dark:fill-gray-400 dark:hover:bg-white/5 dark:hover:fill-gray-400",
        "swiper-no-swiping",
      )}
      {...attributes}
      {...listeners}
    >
      <svg viewBox="0 0 20 20" width="12">
        <path
          d="M7 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 2zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 8zm0 6a2 2 0 1 0
                 .001 4.001A2 2 0 0 0 7 14zm6-8a2 2 0 1 0-.001-4.001A2 2 0 0 0 13 6zm0 2a2 2 0 1 0 .001 4.001A2 2 0 0 0
                 13 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 14z"
        ></path>
      </svg>
    </button>
  )
}
