import clsx from "clsx"

import React, { useEffect, useState } from "react"

export default function Checkbox({
  id,
  name,
  defaultChecked,
  labelColor,
  colorChecked,
  handleChange,
  label,
  closed = false,
  sync = true,
  disabled = false,
}) {
  const [isChecked, setIsChecked] = useState(defaultChecked || false)

  // Logger.debug("Checkbox.jsx");

  useEffect(() => {
    if (isChecked !== defaultChecked) {
      setIsChecked(defaultChecked)
    }
  }, [defaultChecked])

  const handleChanging = () => {
    handleChange()
    if (sync) {
      setIsChecked(!isChecked)
    }
  }

  return (
    <div className={clsx("relative flex items-start", closed && "z-4")}>
      <div className="flex h-6 items-center">
        <input
          id={id}
          aria-describedby="comments-description"
          name={name || id}
          type="checkbox"
          className={clsx("checkbox", colorChecked)}
          onChange={handleChanging}
          checked={isChecked}
          disabled={disabled}
        />
      </div>
      <div className="leading-6">
        <label
          htmlFor={id}
          className={clsx(
            "checkbox-label inline-flex truncate",
            labelColor,
            disabled && "cursor-not-allowed",
          )}
        >
          {label}
        </label>
      </div>
    </div>
  )
}
