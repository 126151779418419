import Toggle from "../../../Checkbox/Toggle"
import {
  CLOSE_DAY_BTN_LABEL,
  DAY,
  DAY_TRIP_LABEL,
  LABEL_DAY_CLOSE,
  LABEL_DAY_OPEN,
  LABEL_DAY_TRIP,
  LEVEL,
  MESSAGE_DAY_CLOSE,
  MESSAGE_DAY_OPEN,
  MESSAGE_DAY_TRIP_NO,
  MESSAGE_DAY_TRIP_YES,
  OPEN_DAY_BTN_LABEL,
  SUBMIT_BTN_LABEL,
  TOGGLE_ITEM_CLOSED,
  TOGGLE_ITEM_DAY_TRIP,
} from "../../../../common/constants"
import clsx from "clsx"
import { useModalStore } from "../../../../common/stores/modal.store"
import { useShallow } from "zustand/react/shallow"

export default function DayGestion({
  isDayTrip,
  isDayClosed,
  dayId,
  updateProperties,
}) {
  const { setModalData, toggleModal } = useModalStore(
    useShallow((state) => ({
      setModalData: state.setModalData,
      toggleModal: state.toggleModal,
    })),
  )

  /**
   * Handle toggle
   * @param id
   * @param isChecked
   * @param title
   * @param message
   * @param labelSubmit
   */
  const handleToggle = (id, isChecked, title, message, labelSubmit) => {
    setModalData({
      title: title,
      message: message,
      labelSubmit: labelSubmit,
      level: LEVEL.WARN,
      confirmCallback: () => {
        updateProperties(id, isChecked, DAY)
      },
    })
    toggleModal()
  }

  /**
   * Handle toggle day trip
   * @param id
   * @param isChecked
   */
  const handleToggleDayTrip = (id, isChecked) => {
    if (isChecked) {
      handleToggle(
        id,
        isChecked,
        LABEL_DAY_TRIP,
        MESSAGE_DAY_TRIP_YES,
        DAY_TRIP_LABEL,
      )
    } else {
      handleToggle(
        id,
        isChecked,
        LABEL_DAY_TRIP,
        MESSAGE_DAY_TRIP_NO,
        SUBMIT_BTN_LABEL,
      )
    }
  }

  /**
   * Handle toggle day closed
   * @param id
   * @param isChecked
   */
  const handleToggleDayClosed = (id, isChecked) => {
    if (isChecked) {
      handleToggle(
        id,
        isChecked,
        LABEL_DAY_CLOSE,
        MESSAGE_DAY_CLOSE,
        CLOSE_DAY_BTN_LABEL,
      )
    } else {
      handleToggle(
        id,
        isChecked,
        LABEL_DAY_OPEN,
        MESSAGE_DAY_OPEN,
        OPEN_DAY_BTN_LABEL,
      )
    }
  }

  return (
    <div className="flex flex-wrap justify-between p-4">
      <div className="flex">
        <Toggle
          state={isDayTrip}
          callback={handleToggleDayTrip}
          id={"dayTrip_" + dayId}
          textColor={"standard-text-color font-medium"}
          bgColor={"standard-bg-color"}
          libelleAfter={TOGGLE_ITEM_DAY_TRIP}
        />
      </div>
      <div className={clsx(isDayClosed && "z-4", "flex")}>
        <Toggle
          state={isDayClosed}
          callback={handleToggleDayClosed}
          id={"dayClosed_" + dayId}
          textColor={"red-text-color font-medium"}
          bgColor={"red-bg-color"}
          libelleAfter={TOGGLE_ITEM_CLOSED}
        />
      </div>
    </div>
  )
}
