import React from "react"

import { Navigate } from "react-router-dom"
import { useAuth } from "../hooks/UseAuth"

export const ProtectedRoute = ({ children }) => {
  const { session } = useAuth()
  if (!session) {
    return <Navigate to="/login" />
  }
  return children
}
