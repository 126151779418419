import React, { memo, useMemo } from "react"
import Closed from "./Closed"
import Indicator from "./Indicator"
import { useReservationStore } from "../../../common/stores/reservation.store"
import { useShallow } from "zustand/react/shallow"
import clsx from "clsx"

const TripBooking = memo(function TripBooking({ trip }) {
  const { tripIds } = useReservationStore(
    useShallow((state) => ({
      tripIds: state.tripIds,
    })),
  )

  const { isTripClosed, tripId } = trip

  const isBookingResa = useMemo(
    () => tripIds.includes(tripId),
    [tripIds, tripId],
  )

  return (
    <div className={clsx("relative h-full w-full")}>
      {isTripClosed && <Closed />}
      {isBookingResa && <Indicator color={"bg-gray-600 dark:bg-gray-400"} />}
    </div>
  )
})

export default TripBooking
