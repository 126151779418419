import { create } from "zustand"
import dayjs from "dayjs"
import { Logger } from "../logger"

export const useDaysStore = create((set, get) => ({
  days: [],
  setDays: (days) => set({ days }),
  initDays: () => set({ days: [] }),
  updateDays: (updatedDays) => {
    const currentDays = get().days
    const updatedDaysArray = currentDays?.map((day) => {
      const matchingDay = updatedDays.find(
        (axiosDay) => axiosDay.date === day.date,
      )
      if (matchingDay) {
        return { ...matchingDay }
      }
      return day
    })
    set({ days: updatedDaysArray })
  },
  isLoading: null,
  setIsLoading: (isLoading) => set({ isLoading }),
  selectedDate: (() => {
    const storedDate = sessionStorage.getItem("selectedDay")
    if (storedDate) {
      return dayjs(storedDate)
    } else {
      const currentDate = dayjs().toISOString()
      sessionStorage.setItem("selectedDay", currentDate)
      return dayjs(currentDate)
    }
  })(),
  setSelectedDate: (selectedDate) => {
    sessionStorage.setItem("selectedDay", selectedDate)
    set({ selectedDate })
  },
  resetSelectedDate: () => {
    sessionStorage.removeItem("selectedDay")
    set({ selectedDate: dayjs().toISOString() })
  },
  refresh: false,
  setRefresh: (refresh) => set({ refresh }),
}))

export const handleDate = (date, isLoad) => {
  if (!dayjs(useDaysStore.getState().selectedDate).isSame(dayjs(date), "day")) {
    useDaysStore.setState({ isLoading: isLoad })
    useDaysStore.getState().setSelectedDate(date)
    setTimeout(() => {
      if (useDaysStore.getState().isLoading) {
        useDaysStore.setState({ isLoading: false })
      }
    }, 0)
  }
}

export const handleData = (date, fetchDate, nb) => {
  fetchDate(date, nb)
    .then((response) => {
      useDaysStore.setState({
        days: [...response] || [],
      })
    })
    .catch((error) => {
      Logger.error(error)
    })
}
