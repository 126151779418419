import dayjs from "dayjs"
import { useModalStore } from "../../../../common/stores/modal.store"
import clsx from "clsx"
import { LABEL_TRIP_ADD } from "../../../../common/constants"
import FormAddTrip from "../../../Form/FormAddTrip"
import { PlusIcon } from "@heroicons/react/24/solid"
import { Btn } from "../../../Buttons/Btn"
import { useShallow } from "zustand/react/shallow"
import { memo } from "react"

const DayHeader = memo(function Month({ date, dayId, isDayClosed = false }) {
  const { setModalData, toggleModal } = useModalStore(
    useShallow((state) => ({
      setModalData: state.setModalData,
      toggleModal: state.toggleModal,
    })),
  )
  const formatDate = dayjs(date)
    .locale("fr")
    .format("dddd DD MMM YYYY")
    .toUpperCase()

  const handleAddTrip = () => {
    setModalData({
      title: (
        <span>
          <span>{LABEL_TRIP_ADD}</span>
          <span className={"pl-2 text-red-600"}>{formatDate}</span>
        </span>
      ),
      isForm: true,
      noCancel: true,
      form: FormAddTrip,
      formData: { date, dayId },
      noIcon: true,
    })
    toggleModal()
  }

  return (
    <div
      className={clsx(
        "sticky flex p-4 text-center",
        "white-text-color bg-main font-bold dark:bg-gray-800",
      )}
    >
      <div className={clsx("w-full items-center truncate leading-6")}>
        {formatDate}
      </div>

      {!isDayClosed && dayId && (
        <Btn
          free_style={clsx("absolute right-0 top-0 -m-0 h-full")}
          onClick={handleAddTrip}
          title={"Ajouter une sortie"}
        >
          <PlusIcon className="h-5 w-5" aria-hidden="true" />
        </Btn>
      )}
    </div>
  )
})

export default DayHeader
