import { useMobileStore } from "../../../../common/stores/mobile.store"
import Checkbox from "../../../Checkbox/Checkbox"
import { memo } from "react"

const TripGestion = memo(function TripGestion({
  tripId,
  isTripFull,
  isTripFullEnc,
  isTripClosed,
  handleChange,
}) {
  const { isMobile } = useMobileStore()

  // Logger.debug("TripGestion.jsx");

  return (
    <div className="flex w-full flex-wrap justify-between gap-y-2.5 p-4 lg:grid">
      <Checkbox
        id={"fullTrip_" + tripId}
        defaultChecked={isTripFull}
        colorChecked={"black-text-color"}
        labelColor={
          isTripFull ? "black-text-color font-medium" : "standard-text-color"
        }
        handleChange={() => handleChange("fullTrip_" + tripId, !isTripFull)}
        label="Complet"
      />

      <Checkbox
        id={"encadrantFullTrip_" + tripId}
        defaultChecked={isTripFullEnc}
        colorChecked={"green-text-color"}
        labelColor={
          isTripFullEnc ? "green-text-color font-medium" : "standard-text-color"
        }
        handleChange={() =>
          handleChange("encadrantFullTrip_" + tripId, !isTripFullEnc)
        }
        label={isMobile ? "Enc. complet" : "Encadrement complet"}
      />

      <Checkbox
        id={"closedTrip_" + tripId}
        defaultChecked={isTripClosed}
        colorChecked={"red-text-color"}
        labelColor={
          isTripClosed ? "red-text-color font-medium" : "standard-text-color"
        }
        handleChange={() => handleChange("closedTrip_" + tripId, !isTripClosed)}
        label="Fermé / OFF"
        closed={isTripClosed}
        sync={false}
      />
    </div>
  )
})
export default TripGestion
