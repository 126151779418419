import ky from "ky"
import { showToast } from "../common/toastUtils"
import { useAuth } from "../common/hooks/UseAuth"

const BASE_URI = process.env.REACT_APP_BASE_URI
const IMG_URI = process.env.REACT_APP_IMG_URI

export const AVATAR_URI = IMG_URI + "avatars/"
export const LOGO_URI = IMG_URI + "logo/"
export const WALLPAPER_URI = IMG_URI + "wallpaper/"
export const APP_LOGO = LOGO_URI + "dive-planner-logo.svg"

const buildApiUrl = (endpoint) => `api/${endpoint}`
const buildResaUrl = (endpoint) => buildApiUrl(`resa/${endpoint}`)
const buildCalendarUrl = (endpoint) => buildApiUrl(`calendar/${endpoint}`)
const buildAuthUrl = (endpoint) => `auth/${endpoint}`

export const STATUS_ENDPOINTS = {
  GET_STATUS_URI: buildApiUrl("supervisor/status/get"),
}

export const AUTH_ENDPOINTS = {
  LOGIN_URI: buildAuthUrl("login"),
  LOGOUT_URI: buildAuthUrl("logout"),
}

export const API_ENDPOINTS = {
  GET_TRIPS_OF_RESA: buildResaUrl("get_trips_of_resa"),
  ADD_RESA_URI: buildResaUrl("add"),
  UPDATE_RESA_URI: buildResaUrl("update"),
  DUPLICATE_RESA_URI: buildResaUrl("duplicate"),
  DEL_PARTIAL_RESA_URI: buildResaUrl("delpartial"),
  DEL_RESA_URI: buildResaUrl("delAll"),

  SET_PROPERTY_URI: buildCalendarUrl("set_"),
  GET_DAY_URI: buildCalendarUrl("day/get"),
  GET_BASIC_CAL: buildCalendarUrl("day/get_basic_cal"),
  SET_DAY_PROPERTY_URI: buildCalendarUrl("day/set_"),

  DEL_TRIP_URI: buildCalendarUrl("trip/delTrip"),
  ADD_TRIP_URI: buildCalendarUrl("trip/addTrip"),
  SET_TRIP_PROPERTY_URI: buildCalendarUrl("trip/set_"),
  SORT_RESA_URI: buildCalendarUrl("trip/sort_resa"),
}

let logoutHandled = false

/**
 * Service de gestion des requêtes
 * @returns {{globalRequest: KyInstance, handleResponse: handleResponse}}
 * @constructor
 */
const baseRequest = () => {
  const { logout, session } = useAuth()
  const token = session?.token

  const kyInstance = ky.create({
    prefixUrl: BASE_URI,
  })

  const globalRequest = kyInstance.extend({
    hooks: {
      beforeRequest: [
        (request) => {
          if (token) {
            request.headers.set("Authorization", `Bearer ${token}`)
          }
        },
      ],
      afterResponse: [
        async (_input, _options, response) => {
          if (!response.ok) {
            handleResponse(response)
            logoutHandled = true
          } else {
            logoutHandled = false
          }
        },
      ],
    },
    timeout: 60000,
  })

  /**
   * Méthode de gestion des erreurs
   * @param response
   */
  const handleResponse = (response) => {
    if (logoutHandled) return
    switch (response.status) {
      case 200:
        break
      case 401:
        showToast(true, "Mauvais identifiant/mot de passe")
        break
      case 403:
        logout()
        // logoutHandled = true
        showToast(true, "Session expirée")
        break
      case 404:
        showToast(true, "Erreur 404")
        break
      case 500:
        // logoutHandled = true
        showToast(true, "Erreur 500")
        break
      case 400:
        showToast(true, "Erreur 400")
        break
      default:
        showToast(true, "Erreur inconnue")
        break
    }
  }

  return {
    globalRequest,
    handleResponse,
  }
}

export default baseRequest
