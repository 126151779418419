export default function FixedComponentInput({ onClick, Icon }) {
  return (
    <span
      onClick={onClick}
      className="absolute text-gray-400 inset-y-0 right-0 z-4 flex cursor-pointer items-center pr-3"
    >
      <Icon className="h-5 w-5" />
    </span>
  )
}
