import React, { memo, useEffect, useState } from "react"
import { useRequest } from "../../services/UseRequest"
import { ArrowPathIcon } from "@heroicons/react/24/outline"
import { buildDate } from "../../build-info"
import clsx from "clsx"

const VERSION = process.env.REACT_APP_VERSION

const InfoComponent = memo(function InfoComponent({}) {
  const { onGetApiStatus } = useRequest()
  const [info, setInfo] = useState({
    apiStatus: 0,
    apiVersion: null,
    isLoading: false,
  })

  useEffect(() => {
    const interval = setInterval(() => {
      getApiStatus()
    }, 30000) // Lancer la requête toutes les 30 secondes

    // Exécuter la requête immédiatement lors du montage initial
    getApiStatus()

    // Nettoyer l'intervalle lorsque le composant est démonté
    return () => {
      clearInterval(interval)
    }
  }, [])

  const setInformations = (newInfo) => {
    setInfo((prevState) => ({
      ...prevState,
      ...newInfo,
    }))
  }

  /**
   * Get API Status
   * @returns {Promise<void>}
   */
  const getApiStatus = async () => {
    setInformations({ isLoading: true, apiStatus: 0 })
    const timer = setTimeout(() => {
      onGetApiStatus()
        .then((response) => {
          setInformations({
            apiStatus: response.status,
            apiVersion: response.message,
          })
        })
        .catch((error) => {
          setInformations({ apiStatus: 0 })
        })
        .finally(() => {
          setInformations({ isLoading: false })
        })
    }, 500)
  }

  return (
    <div className={"grid w-full gap-y-2.5 py-1 pt-5 leading-6"}>
      <div className="">
        <b>Dive Planner</b> est une application de gestion de planning de
        plongée sous-marine.
      </div>

      <div className="">
        Elle permet de gérer les plannings de plongée, les réservations, les
        clubs de plongée et les plongeurs.
      </div>

      <div className="relative">
        <div className={"py-3"}>
          <div className={"grid-cold-2 grid items-start gap-y-1.5 text-start"}>
            <div className={"col-span-2 p-3 pl-0"}>
              <b>Informations techniques</b>
            </div>

            <div className="text-gray-400">App Version :</div>
            <div>
              <b>{VERSION}</b>
            </div>

            <div className="text-gray-400">Date build :</div>
            <div>
              <b>{buildDate}</b>
            </div>

            <div className="text-gray-400">API Status</div>

            <div className={"flex items-center space-x-5"}>
              {info.apiStatus === 1 ? (
                <div className={"h-5 w-5 rounded-full bg-green-600"}></div>
              ) : (
                <div className={"h-5 w-5 rounded-full bg-red-600"}></div>
              )}

              <button onClick={getApiStatus} className={"relative"}>
                <div className={clsx(info.isLoading && "animate-spinner")}>
                  <ArrowPathIcon className={clsx("h-5 w-5")} />
                </div>
              </button>
            </div>

            <div className={"text-gray-400"}>API Version :</div>
            <div>
              <b>{info.apiVersion}</b>
            </div>

            <div className="text-gray-400">Contact :</div>
            <div className="">
              <a href="mailto:admin@fbenital.fr">
                <b>admin@fbenital.fr</b>
              </a>
            </div>

            <div className="text-gray-400">Développeur :</div>
            <div>
              <b>Benoit F.</b>
            </div>

            <div className="text-gray-400">Copyright :</div>
            <div>
              <b>© 2023-2024 Dive Planner</b>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})

export default InfoComponent
