const DEFAULT_LOG_LEVEL = "debug"
const LOG_LEVELS = ["info", "debug"]

/**
 * Logger class
 */
export class Logger {
  static #logLevel = DEFAULT_LOG_LEVEL

  /**
   * Set log level
   * @param logLevel
   */
  static setLogLevel(logLevel) {
    if (LOG_LEVELS.includes(logLevel)) {
      this.#logLevel = logLevel
    }
  }

  /**
   * Log info message
   * @param message
   */
  static info(message) {
    this.#log(message, "info")
  }

  /**
   * Log debug message
   * @param message
   */
  static debug(message) {
    this.#log(message, "debug")
  }

  /**
   * Log error message
   * @param message
   */
  static error(message) {
    console.error(message)
  }

  static #log(message, logFunction) {
    if (
      (this.#logLevel === "debug" &&
        (logFunction === "debug" || logFunction === "info")) ||
      (this.#logLevel === "info" && logFunction === "info")
    ) {
      console[logFunction](`[${logFunction.toUpperCase()}] ${message}`)
    }
  }
}
