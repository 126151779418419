import { toast } from "react-toastify"

export const showToast = (isError, message, status) => {
  if (status) {
    if (status === 0) {
      isError ? toast.error(message) : toast.success(message)
    } else if (status === 201) {
      toast.warning(message)
    }
  } else {
    isError ? toast.error(message) : toast.success(message)
  }
}
