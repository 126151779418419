import clsx from "clsx"

export default function Indicator({ color }) {
  return (
    <div
      className={clsx(
        "lg:animate-fadeIn",
        "absolute bottom-0 z-1 h-1 w-full",
        "left-1/2 -translate-x-1/2 transform",
        color,
      )}
    ></div>
  )
}
