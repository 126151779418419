import { LABEL_REQUIRED_INFO } from "../../../common/constants"
import LabelRequired from "./LabelRequired"

export default function LabelRequiredInfo() {
  return (
    <div className={"relative w-full text-right -mt-5"}>
      <LabelRequired className={"mr-1"} />
      <span>{LABEL_REQUIRED_INFO}</span>
    </div>
  )
}
