import { create } from "zustand"

export const sidebarStore = create((set, get) => ({
  isSidebarVisible: window.innerWidth >= 1024,
  toggleSidebar: () => {
    set({ isSidebarVisible: !get().isSidebarVisible })
  },
  toggleMobile: () => {
    window.innerWidth < 1024 && get().toggleSidebar()
  },
}))
