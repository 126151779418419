import { DAY_SHORT_LABEL } from "../../../common/constants"
import clsx from "clsx"
import React, { memo } from "react"

const Day_name = memo(function Day_name({}) {
  return (
    <div className="grid grid-cols-7 py-2 leading-6">
      {DAY_SHORT_LABEL.map((labelDay) => (
        <div key={labelDay} className={clsx("items-center justify-center")}>
          {labelDay}
        </div>
      ))}
    </div>
  )
})

export default Day_name
