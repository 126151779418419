import LabelForm from "./LabelForm"
import FixedComponentInput from "./FixedComponentInput"
import { useRef, useState } from "react"
import ErrorMessage from "./ErrorMessage"
import clsx from "clsx"
import { ExclamationTriangleIcon, EyeIcon, EyeSlashIcon, XMarkIcon } from "@heroicons/react/24/outline"

export default function FormComponent({
  id,
  name,
  type,
  label,
  value,
  onChange,
  disabled = false,
  textarea = false,
  style,
  crossDelete = true,
  onDelete,
  error = false,
  required,
  eye = false,
  maxLength = 50
}) {
  const Component = textarea ? "textarea" : "input"
  const ref = useRef(null)
  const hasError = error && required

  const [typ, setTyp] = useState(type)
  const [eyeIcon, setEyeIcon] = useState(EyeIcon)

  const maxCharTextArea = 500
  const maxLabelTab = [value.length, "/", maxCharTextArea, "caractères max"]

  const handleDelete = () => {
    onDelete()
    ref.current.focus()
  }

  const handleType = () => {
    setTyp(typ === "text" ? "password" : "text")
    setEyeIcon(typ === "text" ? EyeIcon : EyeSlashIcon)
  }

  const handleInputChange = (event) => {
    // Ajuster la valeur pour supprimer les espaces au début
    const adjustedValue = event.target.value.trimStart()
    onChange({
      ...event,
      target: { ...event.target, value: adjustedValue, name: name },
    })
  }

  return (
    <>
      <div className="relative">
        <Component
          ref={ref}
          type={typ}
          id={id}
          name={name}
          className={clsx(
            "input-form relative",
            hasError && "error",
            style ? style : "rounded-lg",
            crossDelete && "pr-10",
            // textarea && "resize-none"
          )}
          placeholder=" "
          value={value}
          onChange={handleInputChange}
          disabled={disabled}
          rows={textarea ? 5 : undefined}
          maxLength={textarea ? maxCharTextArea : maxLength}
        />

        <LabelForm
          id={id}
          label={label}
          textarea={textarea}
          disabled={disabled}
          required={required}
        />

        {crossDelete &&
          value !== undefined &&
          value !== "" &&
          typ !== "password" && (
            <FixedComponentInput onClick={handleDelete} Icon={XMarkIcon} />
          )}
        {eye && value !== undefined && (
          <FixedComponentInput onClick={handleType} Icon={eyeIcon} />
        )}
      </div>
      {textarea && (
        <div
          className={clsx(
            value.length === maxCharTextArea ? "text-red-600" : "opacity-50",
            "relative flex flex-wrap gap-x-1 p-2",
            "dark:text-red-400",
          )}
        >
          {
            // Afficher le nombre de caractères
            maxLabelTab.map((label, index) => (
              <div key={index} className={"flex"}>
                {label}
              </div>
            ))
          }
          {value.length === maxCharTextArea && (
            <ExclamationTriangleIcon className={"h-5 w-5"} />
          )}
        </div>
      )}
      <ErrorMessage label={label} hasError={hasError} />
    </>
  )
}
