import LabelRequired from "./LabelRequired"
import clsx from "clsx"

export default function LabelForm({
  id,
  label,
  textarea = false,
  disabled,
  required = false,
}) {
  return (
    <label
      htmlFor={id}
      className={clsx(
        "label-form",
        textarea ? "top-5" : "top-1/2",
        disabled && "opacity-50",
      )}
    >
      {label}
      {required && <LabelRequired className={"ml-1"} />}
    </label>
  )
}
