import Skeleton from "react-loading-skeleton"

export default function SkeletonTrip() {
  const getSkeletons = () => {
    const skeletons = []
    for (let i = 0; i < 4; i++) {
      skeletons.push(<Skeleton key={i} height={50} />)
    }
    return skeletons
  }

  return (
    <>
      <div className="flex justify-center bg-secondary px-4 py-3">
        <Skeleton width={100} baseColor="#7395AE" highlightColor={"#557A95"} />
      </div>

      <div className="flex border-b bg-main/5 px-4 py-3">
        <Skeleton width={100} />
      </div>
      <div className="flex flex-col space-y-4 p-4">{getSkeletons()}</div>

      <div className="p-4">
        <Skeleton height={30} />
      </div>
    </>
  )
}
