import React, { forwardRef } from "react"
import clsx from "clsx"
import Loader from "../Loading/Loader"

/**
 * Button component
 **/
export const Btn = forwardRef(
  (
    {
      type,
      onClick,
      style,
      title,
      label,
      beforeIcon: IconBefore,
      afterIcon: IconAfter,
      isLoading,
      disabled,
      border,
      py,
      px,
      pictoH,
      pictoW,
      stroke,
      free_style,
      children,
    },
    ref,
  ) => {
    const content = () => {
      return (
        <>
          {IconBefore && (
            <IconBefore
              className={clsx(pictoH, pictoW, "flex-shrink-0", stroke)}
            />
          )}
          {label && (
            <span
              className={clsx(
                "flex-1 truncate",
                IconBefore && "ml-3",
                IconAfter && "mr-3",
              )}
            >
              {label}
            </span>
          )}
          {IconAfter && (
            <IconAfter className={clsx(pictoH, pictoW, "flex-shrink-0")} />
          )}
        </>
      )
    }

    return (
      <button
        ref={ref}
        type={type}
        className={clsx(
          "min-h-10",
          !children ? "base-btn" : "rounded-lg",
          (IconBefore || IconAfter) && "text-left",
          border && "border-btn",
          isLoading && "justify-center",
          "",
          py,
          px,
          style,
          free_style,
        )}
        onClick={onClick}
        title={title}
        disabled={disabled}
      >
        {isLoading ? (
          <Loader height={"h-3"} width={"w-3"} bg={"bg-gray-600"} />
        ) : (
          content()
        )}
        {children}
      </button>
    )
  },
)

Btn.defaultProps = {
  type: "button",
  onClick: () => {},
  style: "simple-btn",
  width: "w-full",
  title: "",
  label: "",
  beforeIcon: null,
  afterIcon: null,
  isLoading: false,
  disabled: false,
  border: false,
  py: "py-2",
  px: "px-3",
  pictoH: "h-6",
  pictoW: "w-6",
  stroke: "stroke-current",
}
