import { createContext, useContext, useMemo } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { useSessionStorage } from "./UseSessionStorage"
import { useDaysStore } from "../stores/days.store"
import { useShallow } from "zustand/react/shallow"

const AuthContext = createContext(undefined)

export const AuthProvider = ({ children }) => {
  const [session, setSession] = useSessionStorage("session", null)
  const navigate = useNavigate()
  const location = useLocation()
  const { resetSelectedDate, initDays } = useDaysStore(
    useShallow((s) => ({
      resetSelectedDate: s.resetSelectedDate,
      initDays: s.initDays,
    })),
  )

  const login = async (data) => {
    setSession(data)
    const from = location.state?.from || "/"
    navigate(from)
  }

  const logout = () => {
    navigate("/login")
    setSession(null)
    sessionStorage.clear()
    initDays()
    resetSelectedDate()
  }

  const value = useMemo(
    () => ({
      session,
      login,
      logout,
    }),
    [session],
  )
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
  return useContext(AuthContext)
}
