import { ListBulletIcon } from "@heroicons/react/24/outline"
import { Menu, MenuButton } from "@headlessui/react"
import ItemsMenu from "../../../utilities/ItemsMenu"
import React, { memo } from "react"
import ItemsViewResa from "./ItemsViewResa"
import ItemsDeleteResa from "./ItemsDeleteResa"
import ItemsModifyResa from "./ItemsModifyResa"
import clsx from "clsx"

const ResaContainerBtn = memo(function ResaContainerBtn({
  resa,
  dayId,
  tripId,
  itemProperties
}) {
  return (
    <div className="flex space-x-1 pt-2">
      <Menu as="div" className="relative ml-auto">
        <MenuButton
          className={clsx("base-btn border bg-white/80 dark:bg-white/5 dark:hover:bg-white/10",
            itemProperties?.textColor, itemProperties?.borderColor,
            "text-opacity-80",
            "hover:text-opacity-100 hover:bg-white/80 hover:border-opacity-80 dark:hover:bg-white/10"
            )}
        >
          <ListBulletIcon className="h-6 w-6" aria-hidden="true" />
          <span className="sr-only">Menu</span>
        </MenuButton>
        <ItemsMenu isBottom={false}>
          <div className="divide-y divide-gray-100 dark:divide-gray-400">
            {[ItemsViewResa, ItemsModifyResa].map((Component, index) => (
              <div key={index} className="p-2">
                <Component resa={resa} itemProperties={itemProperties}/>
              </div>
            ))}
            <div className="p-2">
              <ItemsDeleteResa
                id={resa.id}
                dayId={dayId}
                tripId={tripId}
                startResa={resa.startResa}
                endResa={resa.endResa}
              />
            </div>
          </div>
        </ItemsMenu>
      </Menu>
    </div>
  )
})

export default ResaContainerBtn
