import { MenuItems } from "@headlessui/react"
import clsx from "clsx"

export default function ItemsMenu({ children, isBottom = true }) {
  return (
    <MenuItems
      transition
      className={clsx(
        "day-bg",
        isBottom
          ? "right-0 origin-top-right"
          : "bottom-0 right-14 origin-bottom-right",
        "absolute z-10 mt-2 rounded-md shadow-lg ring-1 ring-black ring-opacity-5",
        "transition data-[closed]:scale-95 data-[closed]:transform",
        "data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75",
        "data-[enter]:ease-out data-[leave]:ease-in focus:outline-none",
      )}
    >
      {children}
    </MenuItems>
  )
}
