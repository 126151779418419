import { Switch } from "@headlessui/react"
import clsx from "clsx"

export default function Toggle({
  state,
  callback,
  id,
  textColor,
  bgColor,
  libelleAfter,
  libelleBefore,
}) {
  return (
    <Switch.Group as="div" className="flex h-7 cursor-pointer items-center">
      {libelleBefore && (
        <Switch.Label as="span" className="mr-2">
          <span className={clsx(state && textColor)}>{libelleBefore}</span>{" "}
        </Switch.Label>
      )}
      <Switch
        id={id}
        checked={state}
        onChange={() => callback(id, !state)}
        className={clsx(
          state
            ? bgColor + " dark:bg-gray-950"
            : "bg-gray-200 dark:bg-gray-900",
          "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent",
          "focus:outline-1 focus:ring-gray-300",
        )}
      >
        <span
          aria-hidden="true"
          className={clsx(
            `${state ? "translate-x-5" : "translate-x-0"}`,
            "pointer-events-none inline-block h-5 w-5 rounded-full bg-white dark:bg-gray-600",
            "shadow ring-0 transition-transform duration-200 ease-in-out",
          )}
        />
      </Switch>
      {libelleAfter && (
        <Switch.Label as="span" className="ml-2">
          <span className={clsx(state && textColor)}>{libelleAfter}</span>{" "}
        </Switch.Label>
      )}
    </Switch.Group>
  )
}
