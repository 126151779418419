import clsx from "clsx"

export default function Loader({
  height,
  width,
  bg = "bg-gray-300",
  height_container = "h-full",
}) {
  return (
    <div className={clsx("flex items-center justify-center", height_container)}>
      <div
        className={clsx(
          "absolute animate-ping rounded-full",
          height,
          width,
          bg,
          "dark:bg-gray-100",
        )}
      ></div>
    </div>
  )
}
