import { ChevronDownIcon } from "@heroicons/react/24/solid"
import React, { memo } from "react"
import { AccordionItem as Item } from "@szhsin/react-accordion"

const AccordionItem = memo(function AccordionItem({
                                                    itemProperties,
                                                    header,
                                                    ...rest
                                                  }) {
  return (
    <Item
      {...rest}
      header={
        <>
          {header}
          <ChevronDownIcon
            className={`accordionChevron h-6 w-6 ${itemProperties?.textColor}`}
          />
        </>
      }
      buttonProps={{
        className: ({ isEnter }) =>
          "accordionBtn " + `${isEnter && "accordionBtnExpanded"}`,
      }}
      contentProps={{ className: "accordionContent" }}
    />
  )
})

export default AccordionItem
