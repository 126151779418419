import { MOTIF_ITEM, RESA } from "../../../common/constants"
import LabelForm from "./LabelForm"
import ErrorMessage from "./ErrorMessage"
import clsx from "clsx"

export default function PurposeSelect({
  reservationPurpose,
  handleReservationPurposeChange,
  error = false,
  required,
}) {
  return (
    <div className="relative">
      <div className="relative flex rounded-md">
        <select
          id={RESA.PURPOSE.field}
          name={RESA.PURPOSE.field}
          className={clsx("input-form rounded-lg", error && "error z-2")}
          value={reservationPurpose || ""}
          onChange={handleReservationPurposeChange}
          required
        >
          <option value="" disabled hidden></option>
          {Object.keys(MOTIF_ITEM).map(
            (motifKey, index) =>
              MOTIF_ITEM[motifKey].statusItem !== undefined && (
                <option key={index} value={MOTIF_ITEM[motifKey].statusItem}>
                  {MOTIF_ITEM[motifKey].longLibelle}
                </option>
              ),
          )}
        </select>

        <LabelForm
          id={RESA.PURPOSE.field}
          label={RESA.PURPOSE.label}
          required={required}
        />
      </div>

      <ErrorMessage label={RESA.PURPOSE.label} hasError={error} />
    </div>
  )
}
