import Calendar from "../../../Calendar/Calendar"
import { useReservationStore } from "../../../../common/stores/reservation.store"
import React from "react"
import { MOTIF_ITEM } from "../../../../common/constants"
import { useShallow } from "zustand/react/shallow"

export default function DetailReservation({ props }) {

  console.log("DetailReservation", props)

  return (
    <Calendar
      readOnly={true}
      selectedDate={props?.startResa}
      classDefault={props?.itemProperties?.bgColor}
    />
  )
}
