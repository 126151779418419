import { create } from "zustand"
import { LEVEL } from "../constants"

const modalInitialState = {
  show: false,
  title: "",
  message: "",
  confirmCallback: null,
  labelSubmit: "",
  noCancel: false,
  isForm: false,
  formData: null,
  level: LEVEL.OK,
  noIcon: false,
  xCross: false,
  form: () => null,
  formProps: {},
}

export const useModalStore = create((set) => ({
  ...modalInitialState,
  setModalData: (newData) => set({ ...newData }),
  toggleModal: () => set((state) => ({ show: !state.show })),
  reset: () => set(modalInitialState),
}))
