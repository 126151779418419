import { useState } from "react"
import Datepicker from "react-tailwindcss-datepicker"
import dayjs from "dayjs"
import { slideStore } from "../../common/stores/slide.store"
import { FORM_DATE_RESA } from "../../common/constants"
import LabelRequired from "../Form/FormComponents/LabelRequired"
import ErrorMessage from "../Form/FormComponents/ErrorMessage"
import clsx from "clsx"
import { PlusIcon } from "@heroicons/react/24/solid"
import { CalendarIcon, XMarkIcon } from "@heroicons/react/24/outline"

export default function Picker({ onDateChange, error, startDate, endDate }) {
  const { asSingle, disabled } = slideStore()
  const [date, setDate] = useState({ startDate, endDate })

  const handleValueChange = (newValue) => {
    setDate(newValue)
    onDateChange(newValue)
  }

  return (
    <div className={"relative"}>
      <Datepicker
        inputId={"date-picker"}
        minDate={dayjs()}
        asSingle={asSingle}
        disabled={disabled}
        inputClassName={clsx(
          "input-form rounded-lg",
          error && "error",
        )}
        // placeholder=
        containerClassName={"picker"}
        useRange={false}
        startWeekOn="mon"
        i18n={"fr"}
        primaryColor={"blue"}
        value={date}
        onChange={handleValueChange}
        displayFormat={"DD-MM-YYYY"}
        startFrom={date ? date.startDate : dayjs()}
        // toggleClassName={"text-gray-600"}
        // toggleIcon={() => <CalendarIcon className={"h-5 w-5 text-gray-600"} />}
      />
      <label
        htmlFor="date-picker"
        className="label-form"
      >
        {FORM_DATE_RESA}
        <LabelRequired className={"ml-1"} />
      </label>

      <ErrorMessage label={FORM_DATE_RESA} hasError={error} />
    </div>
  )
}
