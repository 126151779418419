import React, { memo } from "react"
import clsx from "clsx"
import { ExclamationTriangleIcon, QuestionMarkCircleIcon, StarIcon } from "@heroicons/react/24/outline"
import { ENCADRANT, FORMATION, MOTIF_ITEM } from "../../../../common/constants"

const DiverHeader = memo(function(
  { status, formName, diver, isOption, freeNotes, itemProperties, defaultInstructor }) {

  const IconWithCondition = ({ condition, Icon, className, title }) =>
    condition && <Icon className={className} title={title} />

  return (
    <div className="flex w-full pr-1">
      <div className={clsx("w-full justify-start text-left")}>
          <div className="flex flex-wrap gap-x-1">
            {diver?.firstName}
            <IconWithCondition
              condition={defaultInstructor}
              Icon={StarIcon}
              className={`mt-[1px] h-5 w-5 ${itemProperties.textColor}`}
              title="Instructeur par défaut"
            />
            <IconWithCondition
              condition={freeNotes}
              Icon={ExclamationTriangleIcon}
              className="mt-0.5 h-5 w-5 text-red-800 dark:text-red-500"
            />
            <IconWithCondition
              condition={isOption}
              Icon={QuestionMarkCircleIcon}
              className="mt-0.5 h-5 w-5"
            />
          </div>
          <div className="flex flex-wrap text-left">
            {diver?.name.toUpperCase()}
          </div>
      </div>
      <div className={clsx("w-full justify-end text-right")}>
          <div className={clsx("flex flex-wrap justify-end gap-x-1",
            itemProperties?.textColor
          )}>
            {diver?.lvl && (
              <div className={"flex"}>{diver?.lvl}</div>
            )}
            {diver?.lvl && status !== ENCADRANT && (
              <div className="flex"> - </div>
            )}
            {status !== ENCADRANT &&
              MOTIF_ITEM[status].longLibelle && (
                <div className={"text-right"}>
                  {MOTIF_ITEM[status].longLibelle}
                </div>
              )}
          </div>
          {status === FORMATION && (
            <div className={"flex justify-end"}>{formName}</div>
          )}
        </div>
    </div>
  )
})
export default DiverHeader