import clsx from "clsx"
import React from "react"

export default function SidebarBody({ children }) {
  return (
    <div
      className={clsx(
        "relative p-3 pb-10",
        "mt-16 flex h-full flex-col overflow-hidden overflow-y-auto scrollbar scrollbar-none lg:mt-20",
      )}
    >
      <div className={clsx("space-y-3")}>{children}</div>
    </div>
  )
}
