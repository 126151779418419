import clsx from "clsx"
import { MOTIF_ITEM } from "../../../../common/constants"

export default function Pastille({itemProperties}) {

  return (
    <div className={itemProperties?.pastille}>
      <div className={clsx("relative h-full w-1 text-xl")}></div>
    </div>
  )
}
