import { Btn } from "../../Buttons/Btn"
import {
  CANCEL_BTN_LABEL,
  OPTION_BTN_LABEL,
  SUBMIT_BTN_LABEL,
} from "../../../common/constants"
import { CheckIcon, QuestionMarkCircleIcon } from "@heroicons/react/24/outline"
import { NoSymbolIcon } from "@heroicons/react/24/outline/index"

export default function ActionBtnContainer({
  handleCancel,
  handleOptionFormSubmit,
  handleFormSubmit,
}) {
  return (
    <div className="mt-4 flex flex-row-reverse gap-x-3">
      {handleFormSubmit && (
        <Btn
          onClick={handleFormSubmit}
          label={SUBMIT_BTN_LABEL}
          afterIcon={CheckIcon}
          style={"primary-btn"}
          type={"submit"}
        />
      )}
      {handleOptionFormSubmit && (
        <Btn
          onClick={handleOptionFormSubmit}
          label={OPTION_BTN_LABEL}
          afterIcon={QuestionMarkCircleIcon}
          style={"option-btn"}
        />
      )}
      {handleCancel && (
        <Btn
          onClick={handleCancel}
          label={CANCEL_BTN_LABEL}
          afterIcon={NoSymbolIcon}
          style={"secondary-btn"}
          border={true}
        />
      )}
    </div>
  )
}
