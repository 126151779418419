import { create } from "zustand"

const slideInitialState = {
  show: false,
  title: "",
  requestURI: "",
  asSingle: false,
  disabled: false,
  form: () => null,
  task: 0,
}

export const slideStore = create((set) => ({
  ...slideInitialState,
  setSlideData: (newData) => set({ ...newData }),
  toggleSlide: () => set((state) => ({ show: !state.show })),
  reset: () => set(slideInitialState),
}))
