import clsx from "clsx"

export default function SvgClose({ height }) {
  return (
    <div className={clsx(height)}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 100 100"
        preserveAspectRatio="none"
      >
        <line
          x1="0"
          y1="100"
          x2="100"
          y2="0"
          vectorEffect="non-scaling-stroke"
          strokeWidth="1"
          stroke="#b91c1c"
        />
        <line
          x1="0"
          y1="0"
          x2="100"
          y2="100"
          vectorEffect="non-scaling-stroke"
          strokeWidth="1"
          stroke="#b91c1c"
        />
      </svg>
    </div>
  )
}
