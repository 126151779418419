import DayGestion from "./DayGestion"
import DayBody from "./DayBody"
import clsx from "clsx"
import { memo } from "react"

const DayContent = memo(function Month({ day, updateProperties, deleteTrip }) {
  const { dayId, isDayClosed, isDayTrip } = day

  return (
    <div
      className={clsx(
        "animate-fadeIn",
        "h-[calc(100%-48px)] lg:h-[calc(100%-42px)]",
        "overflow-y-auto overflow-x-hidden scrollbar scrollbar-none",
      )}
    >
      <DayGestion
        isDayTrip={isDayTrip}
        updateProperties={updateProperties}
        isDayClosed={isDayClosed}
        dayId={dayId}
      />

      <DayBody
        day={day}
        updateProperties={updateProperties}
        deleteTrip={deleteTrip}
      />
    </div>
  )
})

export default DayContent
