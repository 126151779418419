import SwiperContent from "./Swiper/SwiperContent"
import { useDaysStore } from "../../common/stores/days.store"
import Loader from "../Loading/Loader"
import { useShallow } from "zustand/react/shallow"
import { memo } from "react"

const Content = memo(function Content({ updateProperties, deleteTrip }) {
  const isLoading = useDaysStore(useShallow((s) => s.isLoading))

  return (
    <>
      {isLoading ? (
        <Loader height={"h-8"} width={"w-8"} height_container={"h-3/4"} />
      ) : (
        <SwiperContent
          updateProperties={updateProperties}
          deleteTrip={deleteTrip}
        />
      )}
    </>
  )
})

export default Content
