import { useEffect, useState } from "react"
import {
  BAPTEME,
  DIVER,
  ENCADRANT,
  FORMATION,
  RESA,
} from "../../common/constants"
import { getStartOfDay } from "../../common/calendarUtils"
import Picker from "../Picker/Picker"
import PurposeSelect from "./FormComponents/PurposeSelect"
import SelectTrip from "./FormComponents/SelectTrip"
import { slideStore } from "../../common/stores/slide.store"
import { diverStore } from "../../common/stores/diver.store"
import { useReservationStore } from "../../common/stores/reservation.store"
import FormComponent from "./FormComponents/FormComponent"
import ActionBtnContainer from "./FormComponents/ActionBtnContainer"
import LabelRequiredInfo from "./FormComponents/LabelRequiredInfo"
import { Logger } from "../../common/logger"
import Checkbox from "../Checkbox/Checkbox"
import { Transition } from "@headlessui/react"

export default function FormDiver({ handleSubmit, handleCancel }) {
  const typeResa = "DIVER"
  const [error, setError] = useState(new Map())
  const { requestURI, task } = slideStore()
  const fieldsToUppercase = ["lastName", "diveLevel", "formName"]
  const fieldsNumber = ["phoneNumber"]

  const [diver, setDiver] = useState(diverStore.getState())
  const [reservation, setReservation] = useState(useReservationStore.getState())

  Logger.debug("FormDiver.jsx render")

  useEffect(() => {
    return () => {
      diver.resetDiver()
      reservation.resetReservation()
    }
  }, [])

  /**
   * Event saisie date reservation
   * @param dateValue
   */
  const handleDateChange = (dateValue) => {
    let start = getStartOfDay(dateValue.startDate)
    let end = getStartOfDay(dateValue.endDate)
    setReservation((prevState) => ({
      ...prevState,
      startDate: start,
      endDate: end,
    }))
  }

  const handleDiver = (event) => {
    const { name, value } = event.target
    if (fieldsNumber.includes(name)) {
      const numbersOnly = value.replace(/[^0-9]/g, "")
      setDiver((prevState) => ({
        ...prevState,
        [name]: numbersOnly,
      }))
    } else {
      const updatedValue = fieldsToUppercase.includes(name)
        ? value.toUpperCase()
        : value
      setDiver((prevState) => ({
        ...prevState,
        [name]: updatedValue,
      }))
    }
  }

  const handleReservation = (event) => {
    const { name, value } = event.target
    if (name === RESA.PURPOSE.field) {
      setReservation((prevState) => ({
        ...prevState,
        reservationPurpose: value,
        defaultInstructor: value !== ENCADRANT && false,
      }))
      setDiver((prevState) => ({
        ...prevState,
        diveLevel: value === BAPTEME ? "" : diver[DIVER.LEVEL.field],
        //formName: value === FORMATION ? "" : reservation[RESA.FORM_NAME.field],
      }))
      // Reset formName if purpose is not formation
      if ( (value !== FORMATION) && (reservation[RESA.FORM_NAME.field] !== "")) {
        setTimeout(() => {
          setReservation((prevState) => ({
            ...prevState,
            formName: "",
          }))
        }, 300);
      }
    } else {
      const updatedValue = fieldsToUppercase.includes(name)
        ? value.toUpperCase()
        : value
      setReservation((prevState) => ({
        ...prevState,
        [name]: updatedValue,
      }))
    }
  }

  /**
   * Event soumission du formulaire
   * @param event
   */
  const handleFormSubmit = (event) => {
    event.preventDefault()
    validateAndSubmit()
  }

  /**
   * Event soumission formulaire en option
   * @param event
   */
  const handleOptionFormSubmit = (event) => {
    event.preventDefault()
    validateAndSubmit(true)
  }

  /**
   * Validation des champs du formulaire
   * @returns {this is (boolean | null)[]}
   */
  const validateForm = () => {
    const formErrors = {
      firstName: !diver[DIVER.FIRST_NAME.field],
      phoneNumber: !diver[DIVER.PHONE.field],
      reservationPurpose: !reservation[RESA.PURPOSE.field],
      startDate: !reservation[RESA.START.field],
      endDate: !reservation[RESA.END.field],
      tripIds:
        reservation[RESA.START.field] && reservation.tripIds.length === 0,
      formName:
        reservation[RESA.PURPOSE.field] === FORMATION &&
        !reservation[RESA.FORM_NAME.field],
    }
    setError(formErrors)
    return Object.values(formErrors).every((error) => !error)
  }

  /**
   * Soumission du formulaire
   * @param isOption
   */
  const validateAndSubmit = (isOption = false) => {
    if (validateForm()) {
      handleSubmit({
        ...diver,
        ...reservation,
        isOption: isOption,
        requestURI: requestURI,
        typeResa: typeResa,
      })
    }
  }

  return (
    <form
      onSubmit={handleFormSubmit}
      className="flex h-full flex-col"
      noValidate
    >
      <div className="mt-8 flex min-h-0 flex-1 flex-col space-y-7">
        <div className="text-lg sm:col-span-6">
          <Picker
            onDateChange={handleDateChange}
            error={error[RESA.START.field] || error[RESA.END.field]}
            startDate={reservation[RESA.START.field]}
            endDate={reservation[RESA.END.field]}
          />
        </div>
        <div className="sm:col-span-6">
          <SelectTrip
            error={error["tripIds"]}
            setReservation={setReservation}
            reservation={reservation}
            task={task}
          />
        </div>
        <div className="flex -space-x-px sm:col-span-6">
          <div className="w-1/2">
            <FormComponent
              type={"text"}
              id={DIVER.FIRST_NAME.field}
              label={DIVER.FIRST_NAME.label}
              name={DIVER.FIRST_NAME.field}
              value={diver[DIVER.FIRST_NAME.field]}
              onChange={handleDiver}
              onDelete={() =>
                handleDiver({
                  target: {
                    value: "",
                    name: DIVER.FIRST_NAME.field,
                  },
                })
              }
              style={["rounded-l-lg"]}
              // crossDelete={false}
              error={error[DIVER.FIRST_NAME.field]}
              required={true}
            />
          </div>
          <div className="w-1/2">
            <FormComponent
              type={"text"}
              id={DIVER.LAST_NAME.field}
              label={DIVER.LAST_NAME.label}
              name={DIVER.LAST_NAME.field}
              value={diver[DIVER.LAST_NAME.field]}
              onChange={handleDiver}
              onDelete={() =>
                handleDiver({
                  target: {
                    value: "",
                    name: DIVER.LAST_NAME.field,
                  },
                })
              }
              style={["rounded-r-lg"]}
              // crossDelete={false}
              error={error[DIVER.LAST_NAME.field]}
            />
          </div>
        </div>

        <div className="sm:col-span-6">
          <FormComponent
            type={"tel"}
            id={DIVER.PHONE.field}
            name={DIVER.PHONE.field}
            label={DIVER.PHONE.label}
            value={diver[DIVER.PHONE.field]}
            onChange={handleDiver}
            onDelete={() =>
              handleDiver({
                target: { value: "", name: DIVER.PHONE.field },
              })
            }
            error={error[DIVER.PHONE.field]}
            required={true}
            maxLength={15}
          />
        </div>

        <div className="sm:col-span-6">
          <FormComponent
            type={"text"}
            id={DIVER.LEVEL.field}
            name={DIVER.LEVEL.field}
            label={DIVER.LEVEL.label}
            value={diver[DIVER.LEVEL.field]}
            onChange={handleDiver}
            disabled={reservation.reservationPurpose === BAPTEME}
            onDelete={() =>
              handleDiver({
                target: { value: "", name: DIVER.LEVEL.field },
              })
            }
            error={error[DIVER.LEVEL.field]}
            // required={reservationPurpose !== BAPTEME}
          />
        </div>

        <div className="sm:col-span-6">
          <PurposeSelect
            handleReservationPurposeChange={handleReservation}
            error={error[RESA.PURPOSE.field]}
            required={true}
            reservationPurpose={reservation[RESA.PURPOSE.field]}
          />
        </div>
        <Transition
          show={reservation.reservationPurpose === FORMATION}
          as="div" unmount={false}
          appear={true}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="sm:col-span-6">
            <FormComponent
              type={"text"}
              id={RESA.FORM_NAME.field}
              name={RESA.FORM_NAME.field}
              label={RESA.FORM_NAME.label}
              value={reservation[RESA.FORM_NAME.field]}
              onChange={handleReservation}
              onDelete={() =>
                handleReservation({
                  target: {
                    value: "",
                    name: RESA.FORM_NAME.field,
                  },
                })
              }
              error={error[RESA.FORM_NAME.field]}
              required={true}
            />
          </div>
        </Transition>

        <div className="sm:col-span-6">
          <FormComponent
            type={"text"}
            id={RESA.FREE_NOTES.field}
            name={RESA.FREE_NOTES.field}
            label={RESA.FREE_NOTES.label}
            value={reservation[RESA.FREE_NOTES.field]}
            onChange={handleReservation}
            textarea={true}
            crossDelete={false}
          />
        </div>

        <div className="sm:col-span-6">
          <div className="relative flex justify-between">
            {reservation.reservationPurpose === ENCADRANT && (
              <div className={"flex w-full"}>
                <div className="h-6">
                  <Checkbox
                    id={RESA.DEFAULT_INSTRUCTOR.field}
                    name={RESA.DEFAULT_INSTRUCTOR.field}
                    defaultChecked={reservation[RESA.DEFAULT_INSTRUCTOR.field]}
                    labelColor={"standard-text-color"}
                    colorChecked={"standard-text-color"}
                    handleChange={() => {
                      handleReservation({
                        target: {
                          value: !reservation[RESA.DEFAULT_INSTRUCTOR.field],
                          name: RESA.DEFAULT_INSTRUCTOR.field,
                        },
                      })
                    }}
                  />
                </div>
                <label
                  htmlFor={RESA.DEFAULT_INSTRUCTOR.field}
                  className="block leading-6"
                >
                  {RESA.DEFAULT_INSTRUCTOR.label}
                </label>
              </div>
            )}
            <LabelRequiredInfo />
          </div>
        </div>
      </div>

      <ActionBtnContainer
        handleCancel={handleCancel}
        handleFormSubmit={handleFormSubmit}
        handleOptionFormSubmit={handleOptionFormSubmit}
      />
    </form>
  )
}
