import React from "react"
import clsx from "clsx"
import { Transition } from "@headlessui/react"

export default function Backdrop({ onClick, show }) {
  return (
    <Transition
      show={show}
      enter="ease-out duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="ease-in duration-200"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div
        className={clsx(
          "z-7 lg:hidden",
          "fixed left-0 top-0 h-full w-full bg-black/10 backdrop-blur-sm"
        )}
        onClick={onClick}
      />
    </Transition>
  )
}
