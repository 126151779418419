import { memo, useEffect, useState } from "react"
import { getMargin, getPeriodsLabel, periodsOrder } from "../../../common/utils"
import dayjs from "dayjs"
import Loader from "../../Loading/Loader"
import LabelRequired from "./LabelRequired"
import ErrorMessage from "./ErrorMessage"
import { ERROR_ITEM_SELECT, SELECT_ITEM, SELECTED_ITEM, TASK } from "../../../common/constants"
import clsx from "clsx"
import Checkbox from "../../Checkbox/Checkbox"
import { Menu, MenuButton, MenuItems } from "@headlessui/react"
import { VariableSizeList as List } from "react-window"
import { Logger } from "../../../common/logger"
import { useRequest } from "../../../services/UseRequest"

/**
 * Méthode pour calculer les périodes
 * @param basicCalendar
 * @param id
 * @returns {{}}
 */
const calculatePeriodLists = (basicCalendar, id) => {
  const periods = basicCalendar.reduce((list, day) => {
    const { trips } = day
    trips.forEach((trip) => {
      if (!trip.isTripClosed && (id ? true : !trip.isTripFull)) {
        const period = getPeriodsLabel(trip.tripStartAt, false, true)
        if (!list[period]) {
          list[period] = []
        }
        list[period].push(trip.tripId)
      }
    })
    return list
  }, {})
  return sortingPeriods(periods)
}

/**
 * Méthode pour trier les périodes
 * @param periods
 * @returns {{}}
 */
const sortingPeriods = (periods) => {
  const sortedPeriods = {}
  periodsOrder.forEach((period) => {
    if (periods[period]) {
      sortedPeriods[period] = periods[period]
    }
  })
  return sortedPeriods
}

export default function SelectTrip({
  error,
  reservation,
  setReservation,
  task,
}) {
  const { startDate, endDate, tripIds, id } = reservation
  const [btnState, setBtnState] = useState({
    isDisable: true,
    isLoading: false,
    isSuccess: false,
  })
  const [basicCalendar, setBasicCalendar] = useState([])
  const [periodLists, setPeriodLists] = useState({})

  const { onGetBasicCalendar, onGetTripsOfResa } = useRequest()

  Logger.debug("SelectTrip.jsx")

  useEffect(() => {
    if (task !== TASK.TRIP_NEW) {
      reset()
    }
    if (dayjs(startDate).isValid() && dayjs(endDate).isValid()) {
      handleDetail(startDate, endDate, id)
    }
  }, [endDate, startDate])

  /**
   * Vérifier si une réservation a été sélectionnée
   */
  useEffect(() => {
    if (tripIds.length > 0) {
      excludeTrips()
      updateBtnState({
        isSuccess: true,
      })
    } else {
      updateBtnState({
        isSuccess: false,
      })
    }
  }, [tripIds])

  /**
   * Vérifier si la liste des périodes est prête
   * et sélectionner tous les voyages pour une nouvelle réservation
   */
  useEffect(() => {
    if (periodLists && Object.keys(periodLists).length > 0) {
      if (task === TASK.FULL_NEW) {
        handleAllCheck()
      }
    }
  }, [periodLists])

  /**
   * Méthode pour exclure les voyages complets mais non cochés
   */
  const excludeTrips = () => {
    Object.values(periodLists).forEach((list) => {
      list.forEach((tripId) => {
        const trip = basicCalendar
          .flatMap((day) => day?.trips)
          .find((trip) => trip.tripId === tripId)
        if (trip.isTripFull && !tripIds.includes(tripId)) {
          const period = getPeriodsLabel(trip.tripStartAt, false, true)
          periodLists[period].splice(periodLists[period].indexOf(tripId), 1)
        }
      })
    })
  }

  /**
   * Méthode pour mettre à jour le statut du bouton
   * @param newStateValues
   */
  const updateBtnState = (newStateValues) => {
    setBtnState((prevState) => ({
      ...prevState,
      ...newStateValues,
    }))
  }

  /**
   * Méthode pour mettre à jour les identifiants de voyage
   * @param tripIds
   */
  const setReservationCheckeds = (tripIds) => {
    setReservation((prevState) => ({
      ...prevState,
      tripIds: tripIds,
    }))
  }

  /**
   * Méthode pour initialiser les données
   * @param response
   */
  const init = (response) => {
    setBasicCalendar(response)
    setPeriodLists(calculatePeriodLists(response, id))

    updateBtnState({
      isSuccess: true,
      isDisable: false,
      isLoading: false,
    })
  }

  /**
   * Méthode pour réinitialiser les données
   */
  const reset = () => {
    setBasicCalendar([])
    setPeriodLists({})
    updateBtnState({ isDisable: true, isSuccess: false, isLoading: false })
    tripIds.length > 0 && setReservationCheckeds([])
  }

  /**
   * Méthode pour récupérer les détails
   * @param start
   * @param end
   * @param id
   */
  const handleDetail = (start, end, id) => {
    updateBtnState({ isLoading: true })
    start &&
      end &&
      onGetBasicCalendar(start, end)
        .then(async (response) => {
          init(response, id)
          if (task === TASK.UPDATE || task === TASK.DUPLICATE) {
            /**
             * Récupérer les voyages de la réservation
             */
            await onGetTripsOfResa(id, start, end)
              .then((detail) => {
                setReservation((prevState) => ({
                  ...prevState,
                  tripIds: detail,
                }))
              })
              .catch((error) => {
                Logger.error(error)
              })
          }
        })
        .catch((error) => {
          Logger.error(error)
        })
  }

  /**
   * Méthode pour gérer la sélection de tous les voyages
   */
  const handleAllCheck = () => {
    const allTripsId = Object.values(periodLists).flatMap((list) => list)
    check(allTripsId, true)
  }

  /**
   * Méthode pour gérer la sélection de tous les voyages d'un créneau
   * @param period
   * @param isChecked
   */
  const handlePeriodCheck = (period, isChecked) => {
    const periodIds = periodLists[period]
    check(periodIds, isChecked)
  }

  /**
   * Méthode pour gérer la sélection d'un voyage
   * @param id
   * @param isChecked
   */
  const handleCheck = (id, isChecked) => {
    check([id], isChecked)
  }

  /**
   * Méthode pour gérer la sélection
   * @param ids
   * @param isChecked
   */
  const check = (ids, isChecked) => {
    const updatedTripIdsSet = new Set(tripIds)
    const allTrips = basicCalendar.flatMap((day) => day?.trips)

    ids.forEach((id) => {
      let trip = allTrips.find((trip) => trip.tripId === id)
      if (isChecked) {
        updatedTripIdsSet.add(id)
        trip?.tripDiversCount + 1 >= trip?.tripDiversMax &&
          (trip.isTripFull = true)
      } else {
        trip.isTripFull && (trip.isTripFull = false)
        updatedTripIdsSet.delete(id)
      }
    })
    const updatedTripIds = Array.from(updatedTripIdsSet)
    setReservationCheckeds(updatedTripIds)
  }

  /**
   * Verification si liste déja allChecked
   * @param period
   * @returns {*}
   */
  const isListAllChecked = (period) => {
    const list = periodLists[period]
    return list.every((id) => tripIds.includes(id))
  }

  const style = () => {
    return clsx(
      "flex w-full justify-center border px-2.5 py-3 focus:z-2",
      "relative h-12 rounded-lg py-2",
      "disable leading-6",
      error && !btnState.isLoading && "error",
      btnState.isSuccess
        ? "border-green-200 bg-green-50 font-semibold text-green-600" +
            "dark:border-green-800 dark:bg-[#1b2c25] dark:text-green-700"
        : "border-gray-400",
    )
  }

  const Row = memo(({ index, style }) => {
    const day = basicCalendar[index]
    const { dayId, isDayClosed, isDayTrip, trips, date } = day
    return (
      <div
        key={dayId}
        style={style}
        className={clsx(
          index % 2 === 0
            ? "bg-gray-50 dark:bg-[#1d232a] dark:bg-opacity-10"
            : "bg-white dark:bg-[#1d232a] dark:bg-opacity-5",
          "flex flex-col",
          isDayClosed && "red-text-color",
        )}
      >
        <div className={clsx("flex flex-row px-4 py-2 leading-6")}>
          {dayjs(date).locale("fr").format("dddd DD MMMM YYYY").toUpperCase()}
        </div>

        <div
          className={clsx(
            "flex flex-row leading-6",
            !isDayClosed && "px-4 lg:py-3",
          )}
        >
          {trips.map((trip, index) => {
            const label = getPeriodsLabel(trip.tripStartAt, isDayTrip, true)
            const i = periodsOrder.indexOf(label)
            const { tripId, isTripFull, isTripClosed, isTripFullEnc } = trip
            const disable =
              isTripClosed || (isTripFull && !tripIds.includes(tripId))

            const checked = tripIds.includes(tripId)

            const getStyle = () => {
              if (isTripFullEnc && !isTripFull)
                return "green-text-color font-extrabold"
              if (isTripFull) return "black-text-color font-extrabold"
              if (isTripClosed) return "red-text-color font-extrabold"
              return checked
                ? "standard-text-color font-medium"
                : "standard-text-color"
            }

            return (
              <div key={tripId} className={clsx("absolute ml-2", getMargin(i))}>
                <Checkbox
                  id={tripId}
                  defaultChecked={checked}
                  labelColor={getStyle()}
                  colorChecked={getStyle()}
                  handleChange={() =>
                    handleCheck(tripId, !tripIds.includes(tripId))
                  }
                  label={label}
                  disabled={disable}
                  sync={false}
                />
              </div>
            )
          })}
        </div>
      </div>
    )
  })

  return (
    <Menu as="div" className="relative">
      <MenuButton
        className={style()}
        disabled={btnState.isDisable || btnState.isLoading}
      >
        {btnState.isLoading ? (
          <Loader height={"h-3"} width={"w-3"} bg={"bg-gray-600"} />
        ) : (
          <span>
            <span>
              {btnState.isSuccess
                ? SELECTED_ITEM + " (" + tripIds.length + ")"
                : SELECT_ITEM}
            </span>
            <LabelRequired className={"ml-1"} />
          </span>
        )}
      </MenuButton>
      <MenuItems
        transition
        className={clsx(
          "day-bg",
          "origin-top",
          "absolute z-10 mt-0.5 w-full rounded-md shadow-lg ring-1 ring-black ring-opacity-5",
          "overflow-hidden",
          "flex flex-col",
          "transition data-[closed]:scale-95 data-[closed]:transform",
          "data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75",
          "data-[enter]:ease-out data-[leave]:ease-in focus:outline-none",
        )}
      >
        <div
          className={clsx(
            "flex flex-row px-4 py-8 leading-6",
            "sticky top-0 z-40 bg-white dark:bg-[#1d232a]",
          )}
        >
          {Object.entries(periodLists).map(([period, trips]) => {
            const i = periodsOrder.indexOf(period)
            const isAllCheck = isListAllChecked(period)
            return (
              <div
                className={clsx("absolute top-5 ml-2", getMargin(i))}
                key={period}
              >
                <Checkbox
                  id={period}
                  labelColor={
                    isAllCheck
                      ? "standard-text-color font-medium"
                      : "standard-text-color"
                  }
                  colorChecked={"standard-text-color"}
                  defaultChecked={isAllCheck} // Cocher automatiquement la case si tous les éléments sont cochés
                  handleChange={() => handlePeriodCheck(period, !isAllCheck)} // Inverser la sélection lorsque la case est cliquée                                label={period}
                  label={"All " + period}
                />
              </div>
            )
          })}
        </div>

        <div className={clsx("overflow-y-auto overflow-x-hidden")}>
          <List
            height={380}
            itemCount={basicCalendar.length}
            itemSize={(index) => {
              const day = basicCalendar[index]
              return day.isDayClosed ? 35 : 75
            }}
          >
            {Row}
          </List>
        </div>
      </MenuItems>
      <ErrorMessage
        label={"créneaux"}
        hasError={error}
        errorItem={ERROR_ITEM_SELECT}
      />
    </Menu>
  )
}
