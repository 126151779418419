import { slideStore } from "../../common/stores/slide.store"
import { useReservationStore } from "../../common/stores/reservation.store"
import { Btn } from "./Btn"
import { useShallow } from "zustand/react/shallow"

export default function BtnAddDiver({
  item,
  date,
  tripId,
  disable,
  asSingle,
  disabled,
  border,
  viewLabel = true,
  toggleSidebar,
  task,
}) {
  const { setSlideData, toggleSlide } = slideStore(
    useShallow((state) => ({
      setSlideData: state.setSlideData,
      toggleSlide: state.toggleSlide,
    })),
  )
  const { setReservationInfo } = useReservationStore(
    useShallow((state) => ({
      setReservationInfo: state.setReservationInfo,
    })),
  )

  const openForm = () => {
    toggleSlide()
    setSlideData({
      title: item.title,
      requestURI: item.URI,
      form: item.form,
      asSingle: asSingle,
      disabled: disabled,
      task: task,
    })
    setReservationInfo({
      startDate: date ? date : null,
      endDate: date ? date : null,
      tripIds: tripId ? [tripId] : [],
    })
    toggleSidebar && toggleSidebar()
  }

  return (
    <Btn
      onClick={openForm}
      beforeIcon={item.icon}
      label={viewLabel && item.label}
      disabled={disable}
      style={"secondary-btn"}
      border={border}
    />
  )
}
