import Main from "./pages/Main"
import Login from "./pages/Login"

import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Logger } from "./common/logger"
import { XMarkIcon } from "@heroicons/react/24/outline"
import { ExclamationCircleIcon } from "@heroicons/react/24/solid"
import { Route, Routes } from "react-router-dom"
import { AuthProvider } from "./common/hooks/UseAuth"
import { ProtectedRoute } from "./common/routes/ProtectedRoute"

Logger.setLogLevel(process.env.REACT_APP_LOG_LEVEL || "info")

export default function App() {
  console.debug("App.js")

  const contextClass = {
    success: "text-green-700",
    // error: "text-red-700",
    // info: "text-gray-600",
    // warning: "text-orange-400",
    // dark: "bg-white-600",
    default: "bg-white dark:bg-[#191e24] font-gray-600",
  }

  const progressClass = {
    success: "yellow",
    error: "blue",
    info: "text-gray-600",
    warning: "text-orange-400",
    dark: "bg-white-600 text-gray-300",
    default: "bg-yellow-600",
  }

  const CloseButton = ({ closeToast }) => (
    <i
      className="absolute right-0 top-1/4 cursor-pointer px-4 py-3 text-gray-600 hover:text-gray-700"
      onClick={closeToast}
    >
      <XMarkIcon className="h-5 w-5" />
    </i>
  )

  return (
    <AuthProvider>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <Main />
            </ProtectedRoute>
          }
        />
        {/*<Route path="/admin"*/}
        {/*       element={*/}
        {/*           <ProtectedRoute>*/}
        {/*               <AdminDashboard />*/}
        {/*           </ProtectedRoute>*/}
        {/*       }*/}
        {/*/>*/}
      </Routes>
      <ToastContainer
        toastClassName={({ type }) =>
          contextClass[type || "default"] +
          " relative flex bg-white justify-between p-4 min-h-10 lg:rounded-lg shadow-md overflow-hidden " +
          "cursor-pointer dark:bg-[#191e24] text-gray-600 dark:text-gray-300"
        }
        position={"top-right"}
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
        limit={3}
        closeButton={<CloseButton />}
        progressStyle={{
          backgroundColor: (type) => progressClass[type || "default"],
        }}
        icon={<ExclamationCircleIcon className="h-10 w-10 text-gray-600" />}
      />
    </AuthProvider>
  )
}
