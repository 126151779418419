import React from "react"
import { slideStore } from "../../common/stores/slide.store"
import clsx from "clsx"
import { XMarkIcon } from "@heroicons/react/24/outline"
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
  TransitionChild,
} from "@headlessui/react"

export default function Slide({ handleSubmit }) {
  const { form: FormComponent, ...slide } = slideStore()

  const handleReset = (e) => {
    slide.toggleSlide()
    setTimeout(() => {
      slide.reset()
    }, 500)
  }

  const handleSubmiting = (request) => {
    handleSubmit(request)
    handleReset()
  }
  const none = () => {}

  return (
    <Dialog open={slide.show} onClose={none} className="relative z-10">
      <DialogBackdrop
        transition
        className={clsx(
          "fixed inset-0 bg-black/10 backdrop-blur-sm transition-opacity",
          "data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200",
          "data-[enter]:ease-out data-[leave]:ease-in",
        )}
      />
      <div className="fixed inset-0 overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <DialogPanel
              transition
              className="pointer-events-auto relative w-screen max-w-2xl transform transition duration-300 ease-in-out data-[closed]:translate-x-full"
            >
              <TransitionChild>
                <div className="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 duration-300 ease-in-out data-[closed]:opacity-0 sm:-ml-10 sm:pr-4">
                  <button
                    type="button"
                    onClick={handleReset}
                    className="relative rounded-md text-white hover:text-white"
                  >
                    <span className="absolute -inset-2.5" />
                    <span className="sr-only">Close panel</span>
                    <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                  </button>
                </div>
              </TransitionChild>
              <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                <div className="px-4 sm:px-6">
                  <DialogTitle className="text-base font-semibold leading-6 text-gray-900">
                    {slide.title}
                  </DialogTitle>
                </div>
                <div className="relative mt-6 flex-1 px-4 sm:px-6">
                  <FormComponent
                    handleSubmit={handleSubmiting}
                    handleCancel={handleReset}
                  />
                </div>
              </div>
            </DialogPanel>
          </div>
        </div>
      </div>
    </Dialog>
  )
}
