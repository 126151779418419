import { MenuItem } from "@headlessui/react"
import { Btn } from "../Buttons/Btn"

export default function Items({ items }) {
  return (
    <>
      {items.map((item, index) => (
        <MenuItem key={index}>
          <Btn
            beforeIcon={item.icon}
            label={item.label}
            onClick={item.callback}
            style={"secondary-btn"}
          />
        </MenuItem>
      ))}
    </>
  )
}
