import Items from "../../../utilities/Items"
import {
  NAV_ITEM,
  TASK,
  TITLE_DUPLICATE_RESA,
  TITLE_UPDATE_RESA,
} from "../../../../common/constants"
import { DocumentDuplicateIcon, PencilIcon } from "@heroicons/react/24/outline"
import React from "react"
import { API_ENDPOINTS } from "../../../../services/BaseRequest"
import { getStartOfDay } from "../../../../common/calendarUtils"
import { useReservationStore } from "../../../../common/stores/reservation.store"
import { useShallow } from "zustand/react/shallow"
import { slideStore } from "../../../../common/stores/slide.store"
import { clubStore } from "../../../../common/stores/club.store"
import { diverStore } from "../../../../common/stores/diver.store"

function ItemsModifyResa({ resa, itemProperties }) {
  const { setReservationInfo } = useReservationStore(
    useShallow((s) => ({
      setReservationInfo: s.setReservationInfo,
    })),
  )
  const { setSlideData, toggleSlide } = slideStore(
    useShallow((s) => ({
      setSlideData: s.setSlideData,
      toggleSlide: s.toggleSlide,
    })),
  )
  const { setClubInfo } = clubStore(
    useShallow((s) => ({
      setClubInfo: s.setClubInfo,
    })),
  )
  const { setDiverInfo } = diverStore(
    useShallow((s) => ({
      setDiverInfo: s.setDiverInfo,
    })),
  )

  const {
    id,
    startResa,
    endResa,
    tripIds = [],
    typeResa,
    status,
    formName,
    freeNotes,
    defaultInstructor,
  } = resa
  const isGroup = typeResa === "GROUP"

  const onUpdateResa = () => {
    openForm(TITLE_UPDATE_RESA, API_ENDPOINTS.UPDATE_RESA_URI, id, TASK.UPDATE)
  }

  const onDuplicateResa = () => {
    openForm(
      TITLE_DUPLICATE_RESA,
      API_ENDPOINTS.DUPLICATE_RESA_URI,
      id,
      TASK.DUPLICATE,
    )
  }

  const openForm = (title, uri, currentId, task) => {
    setReservationInfo({
      id: currentId,
      reservationPurpose: status,
      startDate: getStartOfDay(startResa),
      endDate: getStartOfDay(endResa),
      tripIds: tripIds,
      formName: formName,
      freeNotes: freeNotes,
      defaultInstructor: defaultInstructor,
    })

    if (isGroup) {
      setClubInfo({
        groupId: resa.group?.groupId,
        clubName: resa.group?.name,
        clubContact: resa.group?.phoneNumber,
        clubDiversCount: resa.group?.clubDiversCount,
        clubEncCount: resa.group?.clubEncCount,
        clubEncSupNeeded: resa.group?.clubEncSupNeeded,
        clubAutoEnc: resa.group?.clubAutoEnc,
      })
    } else {
      setDiverInfo({
        diverId: resa.diver?.diverId,
        firstName: resa.diver?.firstName,
        lastName: resa.diver?.name?.toUpperCase(),
        phoneNumber: resa.diver?.phoneNumber,
        diveLevel: resa.diver?.lvl,
      })
    }

    setSlideData({
      title: title,
      requestURI: uri,
      task: task,
      form: NAV_ITEM[typeResa.toLowerCase()].form,
    })

    toggleSlide()
  }

  const menuItemsUpdate = [
    {
      label: TITLE_UPDATE_RESA,
      callback: () => {
        onUpdateResa()
      },
      icon: PencilIcon,
    },
    {
      label: TITLE_DUPLICATE_RESA,
      callback: () => {
        onDuplicateResa()
      },
      icon: DocumentDuplicateIcon,
    },
  ]

  return <Items items={menuItemsUpdate} />
}

export default ItemsModifyResa
