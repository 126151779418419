import React, { memo } from "react"
import Day from "./Day"

const Week = memo(function Week({ week, readOnly, classDefault, dataMonth }) {
  return (
    <>
      {week.map((day) => {
        const dateFormat = day.date.format("YYYY-MM-DD")
        const dataDay = dataMonth.find((dataDay) => dataDay.date === dateFormat)
        return (
          <Day
            key={dateFormat}
            day={day}
            readOnly={readOnly}
            classDefault={classDefault}
            dataDay={dataDay}
          />
        )
      })}
    </>
  )
})

export default Week
