import dayjs from "dayjs"
import "dayjs/locale/fr"
import isoWeek from "dayjs/plugin/isoWeek"

dayjs.extend(isoWeek)

/**
 * Génération d'un mois
 * @param year
 * @param month
 * @param all
 * @returns
 */
export const generateMonth = (year, month, all) => {
  const firstDate = dayjs(`${year}-${month + 1}-01`)
  const firstDayOfMonth = firstDate.day()
  const offset = firstDayOfMonth === 0 ? 6 : firstDayOfMonth - 1

  const cal = {
    name: firstDate.locale("fr").format("MMMM YYYY"),
    days: Array.from({ length: 42 }, (_, i) => {
      const date = firstDate.add(i - offset, "day")
      return { date, isCurrentMonth: date.month() === month }
    }),
  }
  return all ? cal : cal.days.filter((day) => day.isCurrentMonth)
}

const getStartDate = (year, month) => {
  const firstDate = dayjs(`${year}-${month + 1}-01`)
  const firstDayOfMonth = firstDate.day()
  const offset = firstDayOfMonth === 0 ? 6 : firstDayOfMonth - 1
  return firstDate.subtract(offset, "day")
}

const generateWeeks = (startDate, month) => {
  const weeks = new Map()
  for (let i = 0; i < 42; i++) {
    const date = startDate.add(i, "day")
    const weekNumber = date.isoWeek()

    if (!weeks.has(weekNumber)) {
      weeks.set(weekNumber, [])
    }

    weeks.get(weekNumber).push({ date, isCurrentMonth: date.month() === month })
  }
  return weeks
}

const completeWeeks = (weeks) => {
  for (const [weekNumber, days] of weeks.entries()) {
    if (days.length < 7) {
      const firstDay = days[0].date
      const lastDay = days[days.length - 1].date

      // Ajouter les jours avant la première date
      while (days.length < 7 && firstDay.day() !== 0) {
        days.unshift({
          date: firstDay.subtract(1, "day"),
          isCurrentMonth: false,
        })
      }

      // Ajouter les jours après la dernière date
      while (days.length < 7) {
        days.push({
          date: lastDay.add(1, "day"),
          isCurrentMonth: false,
        })
      }
    }
  }
  return weeks
}

const prepareCalendar = (firstDate, weeks, all) => {
  const cal = {
    name: firstDate.locale("fr").format("MMMM YYYY"),
    weeks: new Map(),
    firstDate: null,
    lastDate: null,
  }

  let firstDateFound = false
  let lastDate = null

  for (const [weekNumber, days] of weeks.entries()) {
    if (all) {
      cal.weeks.set(weekNumber, days)
    } else {
      const filteredDays = days.filter((day) => day.isCurrentMonth)
      if (filteredDays.length > 0) {
        cal.weeks.set(weekNumber, filteredDays)
      }
    }

    if (!firstDateFound && days.length > 0) {
      cal.firstDate = days[0].date
      firstDateFound = true
    }

    if (days.length > 0) {
      lastDate = days[days.length - 1].date
    }
  }

  cal.lastDate = lastDate
  return cal
}

export const generateCalendar = (year, month, all) => {
  const startDate = getStartDate(year, month)
  let weeks = generateWeeks(startDate, month)
  weeks = completeWeeks(weeks)
  const firstDate = dayjs(`${year}-${month + 1}-01`)
  return prepareCalendar(firstDate, weeks, all)
}

/**
 * Calcul de la plage de dates
 * @param days
 * @returns {{start: (*|null), end: (*|null)}}
 */
export function calculateDateRange(days) {
  const lastIndex = days.length - 1
  return {
    start: days.length > 0 ? formatDate(days[0]?.date) : null,
    end: days.length > 0 ? formatDate(days[lastIndex]?.date) : null,
  }
}

export const initMonth = (currentYear, currentMonthIdx) => {
  const months = []
  for (let i = -1; i <= 1; i++) {
    let year = currentYear
    let monthIdx = currentMonthIdx + i

    if (monthIdx < 0) {
      monthIdx = 11
      year -= 1
    } else if (monthIdx > 11) {
      monthIdx = 0
      year += 1
    }
    months.push(...generateMonth(year, monthIdx, false))
  }
  return months
}

/**
 * Formattage d'un objet dayjs en ISO sans milliseconde
 * @param date
 * @returns {*}
 */
export function formatDate(date) {
  return date.format("YYYY-MM-DDTHH:mm")
}

/**
 * Formattage d'une date ISO en DD-MM-YYYY pour affichage
 * @param utc_date
 * @returns {string}
 */
export function toSimpleDate(utc_date) {
  return dayjs(utc_date).locale("fr").format("DD-MM-YYYY")
}

/**
 * Formattage d'une date ISO en DD-MM-YYYY pour affichage avec un jour de plus
 * @param utc_date
 * @param nb
 * @returns {string}
 */
export function toSimpleDatePlus(utc_date, nb) {
  const nextDay = dayjs(utc_date)
    .locale("fr")
    .add(nb || 1, "day")
  return nextDay.format("DD-MM-YYYY")
}

/**
 * Formattage d'une date ISO en DD-MM-YYYY pour affichage avec un jour de moins
 * @param utc_date
 * @param nb
 * @returns {string}
 */
export function toSimpleDateMinus(utc_date, nb) {
  const previousDay = dayjs(utc_date)
    .locale("fr")
    .subtract(nb || 1, "day")
  return previousDay.format("DD-MM-YYYY")
}

/**
 * Formattage d'un object Date en dayjs, en ISO uniquement le debut du jour
 * @param date
 * @returns {*}
 */
export function getStartOfDay(date) {
  if (date === null) return date
  return formatDate(dayjs(date).startOf("day"))
}
