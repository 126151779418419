import { Menu, MenuButton } from "@headlessui/react"
import { ChevronDownIcon, UserCircleIcon } from "@heroicons/react/24/solid"
import ItemsMenu from "../../utilities/ItemsMenu"
import { ABOUT_ITEM, DECONNEXION, LEVEL } from "../../../common/constants"
import { useSessionStorage } from "../../../common/hooks/UseSessionStorage"
import { useAuth } from "../../../common/hooks/UseAuth"
import { AVATAR_URI } from "../../../services/BaseRequest"
import { useRequest } from "../../../services/UseRequest"
import {
  ArrowLeftStartOnRectangleIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline"
import { useModalStore } from "../../../common/stores/modal.store"
import InfoComponent from "../../utilities/InfoComponent"
import Items from "../../utilities/Items"
import { WrenchScrewdriverIcon } from "@heroicons/react/24/outline/index"
import AdminDashboard from "../../view/admin/AdminDashboard"
import { slideStore } from "../../../common/stores/slide.store"
import { useShallow } from "zustand/react/shallow"

export function AvatarHeader() {
  const { onLogoutSubmit } = useRequest()
  const [session] = useSessionStorage("session")
  const { logout } = useAuth()
  const { image, userName, fullName, user_id, role } = session?.user
  const avatar = image ? AVATAR_URI + image : null
  const { setModalData, toggleModal } = useModalStore(
    useShallow((state) => ({
      setModalData: state.setModalData,
      toggleModal: state.toggleModal,
    })),
  )
  const { setSlideData, toggleSlide } = slideStore(
    useShallow((state) => ({
      setSlideData: state.setSlideData,
      toggleSlide: state.toggleSlide,
    })),
  )

  const handleAbout = () => {
    setModalData({
      title: "A propos de Dive Planner",
      Icon: LEVEL.INFO.icon,
      form: InfoComponent,
      noCancel: true,
      isForm: true,
      xCross: true,
      noIcon: true,
    })
    toggleModal()
  }

  const handleAdmin = () => {
    setSlideData({
      title: "Administration",
      form: AdminDashboard,
    })

    toggleSlide()
  }

  const menuItems = [
    {
      label: "Administration",
      icon: WrenchScrewdriverIcon,
      callback: () => {
        handleAdmin()
      },
      show: role === "ROLE_ADMIN",
    },
    {
      label: ABOUT_ITEM,
      icon: InformationCircleIcon,
      callback: () => {
        handleAbout()
      },
      show: true,
    },
    {
      label: DECONNEXION,
      icon: ArrowLeftStartOnRectangleIcon,
      callback: () => {
        handleLogout(user_id)
      },
      show: true,
    },
  ]

  /**
   * Méthode de déconnexion
   * @returns {Promise<void>}
   * @param user_id
   */
  const handleLogout = async (user_id) => {
    await onLogoutSubmit(user_id).then((data) => {
      logout()
    })
  }

  return (
    <Menu as="div" className="relative">
      <MenuButton className="-m-1.5 flex items-center p-1.5">
        <span className="sr-only">Open user menu</span>
        {avatar ? (
          <img
            className="h-10 w-10 rounded-full"
            src={avatar}
            alt="user-avatar"
          />
        ) : (
          <UserCircleIcon className="h-10 w-10" aria-hidden="true" />
        )}
        <span className="hidden lg:flex lg:items-center">
          <span
            className="ml-4 text-sm font-medium leading-6"
            aria-hidden="true"
          >
            {fullName || userName}
          </span>
          <ChevronDownIcon
            className="ml-2 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </span>
      </MenuButton>

      <ItemsMenu>
        <div className="p-2">
          <Items items={menuItems.filter((item) => item.show)} />
        </div>
      </ItemsMenu>
    </Menu>
  )
}
