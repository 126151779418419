import React from "react"
import clsx from "clsx"
import { sidebarStore } from "../../../common/stores/sidebar.store"
import { Btn } from "../../Buttons/Btn"

export default function SidebarBtnToggle() {
  const { isSidebarVisible, toggleSidebar } = sidebarStore()

  return (
    <Btn
      free_style={clsx(
        "transform transition-transform duration-300",
        "absolute -right-16 top-2 z-8 lg:top-4",
        isSidebarVisible ? "-translate-x-16" : "translate-x-0",
      )}
      onClick={toggleSidebar}
    >
      <svg
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={clsx(
          "h-8 w-8 transform transition-transform duration-300",
          isSidebarVisible ? "rotate-180" : "rotate-0",
        )}
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <line x1="4" y1="6" x2="14" y2="6" />
        <line x1="4" y1="18" x2="14" y2="18" />
        <path d="M4 12h17l-3 -3m0 6l3 -3" />
      </svg>
    </Btn>
  )
}
