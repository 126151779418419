import { Logger } from "../../../../common/logger"
import { refresh } from "../../../../common/utils"
import { useDaysStore } from "../../../../common/stores/days.store"
import { useShallow } from "zustand/react/shallow"
import { useRequest } from "../../../../services/UseRequest"
import {
  DELETE_PARTIAL_RESA_BTN_LABEL,
  DELETE_RESA_BTN_LABEL,
  LABEL_DEL_PARTIAL_RESA,
  LABEL_DEL_RESA,
  LEVEL,
  MESSAGE_DEL_PARTIAL_RESA,
  MESSAGE_DEL_RESA,
} from "../../../../common/constants"
import { TrashIcon, XMarkIcon } from "@heroicons/react/24/outline"
import { useModalStore } from "../../../../common/stores/modal.store"
import Items from "../../../utilities/Items"

function ItemsDeleteResa({ id, dayId, tripId, startResa, endResa }) {
  const { onHandleDeleteResa, onHandleDeletePartialResa } = useRequest()
  const { setModalData, toggleModal } = useModalStore(
    useShallow((s) => ({
      setModalData: s.setModalData,
      toggleModal: s.toggleModal,
    })),
  )

  const { updateDays } = useDaysStore(
    useShallow((s) => ({
      updateDays: s.updateDays,
    })),
  )

  const handleDelResa = (id, start, end) => {
    onHandleDeleteResa(id, start, end)
      .then((response) => {
        updateDays(response?.days)
      })
      .catch((error) => {
        Logger.error(error)
      })
      .finally(() => {
        refresh()
      })
  }

  const handleDelPartialResa = (utc_date, tripId, resaId) => {
    onHandleDeletePartialResa(utc_date, tripId, resaId)
      .then((response) => {
        updateDays(response?.days)
      })
      .catch((error) => {
        Logger.error(error)
      })
      .finally(() => {
        refresh()
      })
  }
  const handleModal = (message, title, btnLabel, confirmCallback) => {
    setModalData({
      message,
      title,
      confirmCallback,
      level: LEVEL.WARN,
      labelSubmit: btnLabel,
    })
    toggleModal()
  }

  const onDeleteResa = () => {
    handleModal(MESSAGE_DEL_RESA, LABEL_DEL_RESA, DELETE_RESA_BTN_LABEL, () =>
      handleDelResa(id, startResa, endResa),
    )
  }

  const onDeletePartialResa = () => {
    handleModal(
      MESSAGE_DEL_PARTIAL_RESA,
      LABEL_DEL_PARTIAL_RESA,
      DELETE_PARTIAL_RESA_BTN_LABEL,
      () => handleDelPartialResa(dayId, tripId, id),
    )
  }

  const menuItemsDelete = [
    {
      label: LABEL_DEL_PARTIAL_RESA,
      callback: () => {
        onDeletePartialResa()
      },
      icon: XMarkIcon,
    },
    {
      label: LABEL_DEL_RESA,
      callback: () => {
        onDeleteResa()
      },
      icon: TrashIcon,
    },
  ]

  return <Items items={menuItemsDelete} />
}

export default ItemsDeleteResa
