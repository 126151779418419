import React from "react"
import { useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import DragHandle from "./DragHandle"

export function SortableItem({ children, id }) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id })

  const style = {
    zIndex: isDragging ? 1 : undefined,
    // opacity: isDragging ? 0.4 : undefined,
    transform: CSS.Translate.toString(transform),
    transition,
  }

  return (
    <div className={"relative flex w-full"} ref={setNodeRef} style={style}>
      {children}
      <DragHandle attributes={attributes} listeners={listeners} />
    </div>
  )
}
