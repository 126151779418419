import React, { useState } from "react"
import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core"
import {
  arrayMove,
  rectSortingStrategy,
  SortableContext,
  sortableKeyboardCoordinates,
} from "@dnd-kit/sortable"
import { SortableItem } from "./SortableItem"
import { restrictToVerticalAxis } from "@dnd-kit/modifiers"

export default function SortableList({ items, onChange, renderItem, tripId }) {
  const [active, setActive] = useState(null)
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  )

  return (
    <DndContext
      modifiers={[restrictToVerticalAxis]}
      // collisionDetection={closestCenter}
      sensors={sensors}
      onDragStart={({ active }) => setActive(active)}
      onDragEnd={({ active, over }) => {
        if (over && active.id !== over.id) {
          const activeIndex = items.findIndex(({ id }) => id === active.id)
          const overIndex = items.findIndex(({ id }) => id === over.id)

          onChange(arrayMove(items, activeIndex, overIndex))
        }
        setActive(null)
      }}
      onDragCancel={() => setActive(null)}
    >
      <SortableContext
        items={items}
        strategy={rectSortingStrategy}
        id={tripId}
        key={tripId}
      >
        <div className={"flex flex-col gap-3"}>
          {items.map((item) => {
            return (
              <SortableItem key={item.id} id={item.id}>
                {renderItem(item)}
              </SortableItem>
            )
          })}
        </div>
      </SortableContext>
    </DndContext>
  )
}
