import { ERROR_CONNEXION_ITEM } from "../common/constants"
import FormLogin from "../components/Form/FormLogin"
import { useRequest } from "../services/UseRequest"
import clsx from "clsx"
import { WALLPAPER_URI } from "../services/BaseRequest"
import { useAuth } from "../common/hooks/UseAuth"
import SidebarHeader from "../components/Sidebar/SidebarComponents/SidebarHeader"

export default function Login() {
  const { onLoginSubmit } = useRequest()
  const { login } = useAuth()

  /**
   * Méthode de connexion
   * @param username
   * @param password
   * @returns {Promise<void>}
   */
  const handleLogin = async (username, password) => {
    await onLoginSubmit(username, password)
      .then((data) => {
        login(data)
      })
      .catch((error) => {
        console.error(ERROR_CONNEXION_ITEM, error)
        // showToast(true, ERROR_CONNEXION_ITEM);
      })
  }

  return (
    <div className={"h-screen"}>
      <div className={clsx("flex min-h-full flex-1 opacity-100")}>
        <div className="day-bg relative flex flex-1 flex-col justify-center px-4 py-12 pt-0 transition duration-1000 ease-in-out sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm space-y-5 lg:w-96">
            {/*<div>*/}
            {/*    <img*/}
            {/*        className={clsx(*/}
            {/*            "mx-auto h-20 w-auto rounded-2xl border border-gray-200 bg-white",*/}
            {/*            "dark:border-gray-700 dark:bg-[#191e24]"*/}
            {/*        )}*/}
            {/*        src={APP_LOGO}*/}
            {/*        alt="dive-planner-logo"*/}
            {/*    />*/}
            {/*</div>*/}
            <SidebarHeader position={"relative"} classes={"justify-center"} />

            <div className="mt-8">
              <FormLogin handleLogin={handleLogin} />
            </div>
          </div>
        </div>
        <div className="relative hidden w-0 flex-1 lg:block">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src={WALLPAPER_URI + Math.floor(Math.random() * 3) + ".jpg"}
            alt=""
          />
        </div>
      </div>
    </div>
  )
}
