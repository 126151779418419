import { EyeIcon, StarIcon } from "@heroicons/react/24/outline"
import { Logger } from "../../../../common/logger"
import clsx from "clsx"
import { getStartOfDay, toSimpleDate } from "../../../../common/calendarUtils"
import DetailReservation from "./DetailReservation"
import React from "react"
import { useModalStore } from "../../../../common/stores/modal.store"
import { useShallow } from "zustand/react/shallow"
import { useReservationStore } from "../../../../common/stores/reservation.store"
import { useRequest } from "../../../../services/UseRequest"
import Items from "../../../utilities/Items"

function ItemsViewResa({ resa, itemProperties }) {
  const { onGetTripsOfResa } = useRequest()
  const { setModalData, toggleModal } = useModalStore(
    useShallow((s) => ({
      setModalData: s.setModalData,
      toggleModal: s.toggleModal,
    })),
  )
  const { setReservationInfo } = useReservationStore(
    useShallow((s) => ({
      setReservationInfo: s.setReservationInfo,
    })),
  )

  const { id, endResa, startResa } = resa

  const fetchTripsAndShowModal = async () => {
    try {
      resa.tripIds = await onGetTripsOfResa(
        id,
        getStartOfDay(startResa),
        getStartOfDay(endResa),
      )
      setReservationInfo({ ...resa })
    } catch (error) {
      Logger.error(error)
      setReservationInfo({ ...resa, tripIds: [] })
    }
    toggleModal()
  }

  const onViewResa = () => {
    const name =
      resa.typeResa === "GROUP"
        ? resa.group?.name
        : `${resa.diver?.firstName} ${resa.diver?.name?.toUpperCase()}`

    setModalData({
      title: (
        <div className={"space-y-2 p-1 pb-3 leading-6"}>
          <div className={"flex flex-wrap gap-x-1"}>
            <div className={"flex"}>Détail :</div>
            <div className={clsx("flex", itemProperties?.textColor)}>
              {name}
              {resa?.defaultInstructor && (
                <StarIcon
                  className={clsx(
                    "ml-1 flex h-5 w-5",
                    itemProperties?.textColor,
                  )}
                  title={"Instructeur par défaut"}
                />
              )}
            </div>
          </div>
          <div className={"flex gap-x-2 font-medium"}>
            <span className={"text-gray-400"}>Du</span>
            <span>{toSimpleDate(startResa)}</span>
            <span className={"text-gray-400"}>au</span>
            <span>{toSimpleDate(endResa)}</span>
          </div>
        </div>
      ),
      form: DetailReservation,
      formProps: { startResa, itemProperties },
      noCancel: true,
      isForm: true,
      noIcon: true,
      xCross: true,
    })

    fetchTripsAndShowModal()
  }

  const menuItemsView = [
    {
      label: "Voir le détail",
      callback: onViewResa,
      icon: EyeIcon,
    },
  ]

  return <Items items={menuItemsView} />
}

export default ItemsViewResa
