import clsx from "clsx"
import { MapPinIcon as OutlineMapPinIcon } from "@heroicons/react/24/outline"
import { MapPinIcon as SolidMapPinIcon } from "@heroicons/react/24/solid"
import React from "react"
import { EditText } from "react-edit-text"

export default function TripSite({ tripSite, tripId, handleChange }) {
  return (
    <div className={"w-full"}>
      <div
        className={clsx(
          // "text-red-500",
          "std-bg flex h-10 flex-wrap justify-between px-4 py-2",
          "border-b border-b-secondary/20 bg-main/5 dark:bg-[#191e24]",
        )}
      >
        <div className={"relative flex flex-wrap"}>
          <div className={"relative flex"}>
            <label className={"-ml-0.5 flex"}>
              {!tripSite ? (
                <OutlineMapPinIcon className={"h-5 w-5"} />
              ) : (
                <SolidMapPinIcon className={"h-5 w-5"} />
              )}
            </label>
            <div className={"relative"}>
              <>
                <EditText
                  id={"siteTrip_" + tripId}
                  inputClassName="border-0 focus:ring-0 absolute truncate"
                  name="siteTrip"
                  type="text"
                  placeholder="Editer le site"
                  defaultValue={tripSite}
                  onSave={(valueObj) =>
                    handleChange(
                      "siteTrip_" + tripId,
                      valueObj.value,
                      valueObj.previousValue,
                    )
                  }
                  style={{
                    width: "150px",
                    backgroundColor: "transparent",
                    padding: "0 0 0 3px",
                    position: "absolute",
                    top: "-1px",
                    fontWeight: tripSite && "500",
                    color: tripSite ? "#4b5563" : "#999",
                  }}
                  inline
                />
              </>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
