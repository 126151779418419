import React, { memo, useEffect, useState } from "react"
import SortableList from "../../../utilities/SortableList"
import Reservation from "../../Reservation/Reservation"
import { showToast } from "../../../../common/toastUtils"
import { useRequest } from "../../../../services/UseRequest"

const TripReservations = memo(function TripReservations({
  reservations,
  tripId,
  date,
}) {
  const [items, setItems] = useState(() => reservations)
  const { onSortResa } = useRequest()

  useEffect(() => {
    setItems(reservations)
  }, [reservations])

  /**
   * Handle sort change
   * @param sortedItems
   * @returns {Promise<void>}
   */
  const handleSortChange = async (sortedItems) => {
    setItems(sortedItems)
    if (sortedItems.length > 0) {
      let tabResaId = sortedItems.map((item) => item.id)
      onSortResa(tripId, tabResaId).catch((error) => {
        showToast(true, error.message)
      })
    }
  }

  return (
    <SortableList
      items={items}
      onChange={handleSortChange}
      tripId={tripId}
      renderItem={(item) => (
        <Reservation resa={item} tripId={tripId} dayId={date} />
      )}
    />
  )
})

export default TripReservations
