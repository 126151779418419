import React, { memo, useState } from "react"
import BtnAddDiver from "../../Buttons/BtnAddDiver"
import SvgClose from "../../utilities/SvgClose"
import BackdropClose from "../../utilities/BackdropClose"
import {
  CLOSE_TRIP_BTN_LABEL,
  DAY,
  LABEL_TRIP_CLOSE,
  LABEL_TRIP_OPEN,
  LEVEL,
  MESSAGE_TRIP_CLOSE,
  MESSAGE_TRIP_OPEN,
  NAV_ITEM,
  OPEN_TRIP_BTN_LABEL,
  TASK,
  TRIP,
} from "../../../common/constants"
import dayjs from "dayjs"
import { useModalStore } from "../../../common/stores/modal.store"
import TripHeader from "./TripComponents/TripHeader"
import TripGestion from "./TripComponents/TripGestion"
import TripStatus from "./TripComponents/TripStatus"
import TripReservations from "./TripComponents/TripReservations"
import clsx from "clsx"
import TripSite from "./TripComponents/TripSite"
import { Logger } from "../../../common/logger"
import { useShallow } from "zustand/react/shallow"

const Trip = memo(function Trip({
  trip,
  isDayTrip,
  updateProperties,
  deleteTrip,
}) {
  const { setModalData, toggleModal } = useModalStore(
    useShallow((state) => ({
      setModalData: state.setModalData,
      toggleModal: state.toggleModal,
    })),
  )

  const {
    tripId,
    isTripClosed,
    tripReservations,
    tripSite,
    tripDiversMax,
    tripDiversCount,
    isTripFull,
    isTripFullEnc,
    tripStartAt,
    tripStatus,
    closedCause,
  } = trip

  const now = dayjs().subtract(1, DAY)
  const isBefore = dayjs(tripStartAt).isBefore(now)
  const [isExiting, setExiting] = useState(false)

  Logger.debug("Trip.jsx")

  const handleChange = (id, newValue, previousValue) => {
    const isClosingTrip = id.toLowerCase().includes("closed")
    if (isClosingTrip) {
      setModalData({
        title: newValue ? LABEL_TRIP_CLOSE : LABEL_TRIP_OPEN,
        message: newValue ? MESSAGE_TRIP_CLOSE : MESSAGE_TRIP_OPEN,
        labelSubmit: newValue ? CLOSE_TRIP_BTN_LABEL : OPEN_TRIP_BTN_LABEL,
        confirmCallback: () => updateProperties(id, newValue, TRIP),
        level: LEVEL.WARN,
      })
      toggleModal()
    } else if (newValue !== previousValue) {
      updateProperties(id, newValue, TRIP)
    }
  }

  return (
    <div
      className={clsx(
        "day-bg relative mt-0 w-full leading-6 lg:rounded-b-xl",
        `trip-item ${isExiting ? "opacity-0 transition-opacity duration-1000 ease-in-out" : ""}`,
      )}
      onTransitionEnd={() => setExiting(false)}
    >
      <BackdropClose
        show={isTripClosed}
        cause={closedCause}
        type={TRIP}
        id={tripId}
        handleChange={handleChange}
      />
      <TripHeader
        {...{
          isDayTrip,
          tripId,
          tripStartAt,
          tripSite,
          tripDiversMax,
          tripDiversCount,
          isTripClosed,
          handleChange,
          deleteTrip,
          setExiting,
        }}
      />

      <TripSite {...{ tripSite, tripId, handleChange }} />

      <TripGestion
        {...{ tripId, handleChange, isTripClosed, isTripFull, isTripFullEnc }}
      />

      <TripStatus tripStatus={tripStatus} />

      {isTripClosed ? (
        <div className="h-full p-5">
          <SvgClose height="h-36" />
        </div>
      ) : (
        <div className="h-full">
          <div className="relative px-4">
            <TripReservations
              reservations={tripReservations}
              tripId={tripId}
              date={tripStartAt}
            />
          </div>
          <div className="p-4">
            <BtnAddDiver
              item={NAV_ITEM.diver}
              date={tripStartAt}
              tripId={tripId}
              disable={isBefore || isTripFull}
              asSingle
              disabled
              isOpen
              task={TASK.TRIP_NEW}
              border
            />
          </div>
        </div>
      )}
    </div>
  )
})

export default Trip
