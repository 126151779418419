import Skeleton, { SkeletonTheme } from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"
import SkeletonTrip from "./SkeletonTrip"

export default function SkeletonDay({ tripNumber }) {
  const getTripSkeletons = () => {
    const skeletons = []
    for (let i = 0; i < tripNumber; i++) {
      skeletons.push(<SkeletonTrip key={i} />)
    }
    return skeletons
  }

  return (
    <div className="flex h-full flex-col">
      <SkeletonTheme borderRadius="0.5rem">
        <div className="flex items-center justify-between p-4">
          <Skeleton width={100} height={20} />
          <Skeleton width={100} height={20} />
        </div>
        {getTripSkeletons()}
      </SkeletonTheme>
    </div>
  )
}
